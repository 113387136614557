import $ from 'jquery';
var $quickSearch = $("#quicksearch");
var $qsLink = $(".links .search");

var $header = $(".site-header");

// QUICKSEARCH
var qsHeight = $quickSearch.outerHeight();

function hideSearch() {
    $quickSearch.hide();
}
function slideDownSearch() {
    $header.animate({
        top: qsHeight
    }, 300, function () {
        // callback
    });
    $quickSearch.slideDown(300);
}
function slideUpSearch() {
    $header.animate({
        top: '0'
    }, 300, function () {
        // callback
    });
    $quickSearch.slideUp(300);
}
$qsLink.on("click", function (event) {
    event.preventDefault();

    if ($quickSearch.is(":visible")) {
        slideUpSearch();
    } else if ($quickSearch.is(":hidden")) {
        slideDownSearch();
    }
});
hideSearch();
<template>
  <SiteHeader />
  <div class="site-content">
    <div class="site-header">
      <CascadeMenu />
    </div>
    <NotificationsBanner
      v-if="loggedInNotificationMessage.showNotifications && isLoggedin && showLoggedinNavigation"
      :model="loggedInNotificationMessage"
    />
    <NotificationsBanner
      v-else-if="notificationMessage.showNotifications && !isLoggedin && !showLoggedinNavigation"
      :model="notificationMessage"
    />
    <div
      v-if="showLoggedinNavigation && isLoggedin"
      class="container-xl"
    >
      <div class="row dashboard__wrapper d-flex">
        <div class="col-md-4 col-lg-3 pt-3 sidebar-container">
          <DashboardSideBar />
          <SideBar />
        </div>
        <RouterView />
      </div>
    </div>
    <div v-else>
      <RouterView />
    </div>
  </div>
  <CustomerReport />
  <InfoModal
    id="systemerror"
    title="Något gick fel!"
    body="<p>Vänligen ladda om sidan och försök igen</p>"
  />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import CascadeMenu from '../../components/CascadeMenu.vue';
import CustomerReport from '../../components/CustomerReport.vue';
import NotificationsBanner from '../../components/NotificationsBanner.vue';
import InfoModal from '../../components/modals/InfoModal.vue';
import SiteHeader from '../../components/SiteHeader.vue';
import SideBar from '../../components/SideBar.vue';
import DashboardSideBar from '../../components/DashboardSideBar.vue';
import { CUSTOMER_INFO_FETCH } from '../store/modules/customerInfoContex';

const store = useStore(); 

const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const showLoggedinNavigation = computed(() => store.state.appContext.showLoggedinNavigation);
const loggedInNotificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.loggedInNotificationMessage);
const notificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.notificationMessage);

onMounted(() => {
        if (!store.state.customerInfoContex.loaded && store.state.appContext.isLoggedin) {
                store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: false });
        }
});
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.sidebar-container {
  background: $night-rider;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }
}
</style>
export const getCreditChartData = (creditReport) => {

    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries)
    {
        return creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries.sort((a, b) => a.date.timeStamp.value - b.date.timeStamp.value).map((chartData) => {
            return {
                timestamp: chartData.date.timeStamp.value,
                usedCredits: chartData.usedCreditOnAccountCredits,
                grantedCredits: chartData.limitAccountCredits,
                unitOfAmount: "SEK"
            }
        })
    }

    return null;
}
<template>
  <div
    class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3"
    data-testid="dashboard-my-uc-score-page"
  >
    <MobileMenu />
    <div class="row d-md-none pt-4">
      <div class="col-12">
        <RouterLink
          class="return__overview-btn d-flex align-items-center"
          to="/mina-tjanster"
        >
          <i class="far fa-chevron-left fa-xs fa-fw mr-1 color-white" />
          <span class="body-small font-weight-medium color-white">Översikt</span>
        </RouterLink>
      </div>
    </div>
    <CircleLoader v-if="pending" />
    <template v-else>
      <UcScore
        v-if="loaded && customerCreditInfo.creditReport.creditwatchScoreList !== null"
        size="lg"
        :marker-radius="25"
        :report="customerCreditInfo"
      />
      <div class="col-12 dashboard__card large-padding">
        <div class="question-uc-score-wrapper">
          <i
            v-if="model.questionUCScore !== null && model.questionUCScore.value !== ''"
            class="fal fa-lg fa-question-square"
            @click="onQuestionCardClicked"
          />
          <template v-if="loaded && customerCreditInfo.creditReport.creditScore === null">
            <p v-html="model.descriptionNoScoreGrade.value" />
          </template>
        </div>
        <h2 class="h2-bold">
          {{ model.headline.value }}
        </h2>
        <p v-html="model.description.value" />
      </div>
    </template>
    <RelatedArticles
      v-if="model.relatedArticles.length > 0"
      :articles="model.relatedArticles.expandedValue"
    />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import $ from 'jquery';
import UcScore from '../components/charts/UcScore.vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import CircleLoader from '../components/CircleLoader.vue';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex'
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';

const props = defineProps({
    model: Object,
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const loaded = computed(() => store.state.customerCreditInfoContex.loaded);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "UC_SCORE" });
    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }
    if (!store.state.appContext.showSideBar) {
        store.dispatch(APP_SHOW_SIDEBAR);
    }
});

const onQuestionCardClicked = () => {
    $('.question-uc-score-card-overlay').show();
}

</script>

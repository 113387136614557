<template>
  <div
    class="dashboard col-md-8 col-lg-9"
    data-testid="dashboard-financial-skills-page"
  >
    <div class="financial-listing-page">
      <div class="row">
        <div class="col-12 d-md-none">
          <RouterLink
            class="return__overview-btn d-flex align-items-center mb-2"
            to="/mina-tjanster"
          >
            <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
            <span class="body-small font-weight-medium">Översikt</span>
          </RouterLink>
        </div>
        <div class="col-12">
          <div class="dashboard__card article-listing-header-content">
            <h3>{{ model.headline.value }}</h3>
            <p v-html="model.description.value" />
            <div
              v-if="model.tags != null"
              class="tag-label-container"
            >
              <span
                class="tag-label"
                @click="setTag('Alla')"
              >Alla</span>
              <span
                v-for="(tag, index) in model.tags.value"
                :key="`financial_article_tag_${index}`"
                class="tag-label" 
                @click="setTag(tag)"
              >
                {{ tag }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        id="article-list-grid"
        class="row"
      >
        <template v-for="(article, index) in articles">
          <div
            v-if="!article.Hide"
            :key="`financial_article_item_${index}`"
            class="col-md-6 col-lg-4 col-12 card-container"
            @click="router.push({ path: article.Url })"
          >
            <div class="card">
              <img :src="article.TopImage.Url">
              <div class="card-content">
                <h5>{{ article.Headline }}</h5>
                <div
                  v-if="article.SelectedTags != null"
                  class="tag-label-container"
                >
                  <span
                    v-for="(tag, index) in article.SelectedTags.split(',')"
                    :key="`financial_article_item__tag_${index}`"
                    class="tag-label"
                  >
                    {{ tag }}
                  </span>
                </div>
                <p v-html="article.ShortDescription" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="model.relatedArticles.value != null"
        class="financial-listing-page financial-listing-page-container"
      >
        <div class="row row-grid related-articles-section">
          <div class="col col-3 col-md-4">
            <hr>
          </div>
          <div
            class="col col-6 col-md-4 section-header"
            style="text-align:center;"
          >
            <span>Relaterade artiklar</span>
          </div>
          <div class="col col-3 col-md-4">
            <hr>
          </div>
        </div>
        <RelatedArticles :articles="model.relatedArticles.expandedValue" />
      </div>
    </div>
  </div>
  <MobileBottomMenu />
</template>

<script setup>
import RelatedArticles from '../components/RelatedArticles.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted } from 'vue';
import { ARTICLES_FETCH } from '../Scripts/store/modules/articlesContext';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex';

const props = defineProps({ model: Object });
const store = useStore();
const router = useRouter();

const articles = computed(() => store.state.articlesContex.articles);

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    if (!store.state.articlesContex.loaded) {
        store.dispatch(ARTICLES_FETCH, { id: props.model.contentLink.id });
    }
    store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "ECONOMY_KNOWLEDGE" });
});

const setTag = (tag) => {
    for (let article of articles.value) {
        const tags = article.SelectedTags.split(',');
        if (tag !== 'Alla') {
            article.Hide = true;
        } else {
            article.Hide = false;
        }
        for (const articletag of tags) {
            if (articletag === tag) {
                article.Hide = false;
                break;
            }
        }
    }
};
</script>

<template>
  <div>
    <CircleLoader v-if="customerEventsPending" />
    <Reloader
      v-if="customerEventsFailed"
      @reload="reFetchCustomerEvents"
    />
    <div v-if="customerEventsLoaded">
      <div
        v-if="customerEvents.length > 0"
        class="row flex-column"
      >
        <div class="col-12">
          <div
            v-if="searchEnabled"
            class="input__search-wrapper position-relative"
          >
            <i class="far fa-search fa-fw" />
            <input
              v-if="searchEnabled"
              v-model="filterInputModel"
              type="text"
              class="form-control mb-3 w-md-75"
              placeholder="Sök datum, händelse eller aktör."
              @keyup="filterResult"
            >
          </div>
        </div>
        <div class="customer-events block__enento__accordion">
          <div class="panel-group">
            <div
              v-for="(customerEvent, customerEventIndex) in customerEventsChunk"
              :key="`event_${customerEventIndex}`"
            >
              <div
                v-if="customerEventIndex < itemsPerPage"
                :class="`${notRead(customerEvent.decisionDate)} customer-events-item panel-heading`"
              >
                <a
                  class="d-block collapsed"
                  data-toggle="collapse"
                  :href="`#faq-${customerEvent.eventId}`"
                >
                  <h5
                    v-if="customerEvent.creditRating"
                    class="p-3 mb-0"
                  >
                    {{ customerEvent.creditRating.name }}
                    <p class="font-weight-medium mb-0">{{ customerEvent.eventInfo.title }}</p>
                    <p class="font-weight-light mb-0">{{ formatDate(customerEvent.decisionDate) }}</p>
                  </h5>
                  <h5
                    v-else-if="customerEvent.creditRatingAttempt"
                    class="p-3 mb-0"
                  >
                    {{ customerEvent.creditRatingAttempt.name }}
                    <p class="font-weight-medium mb-0">{{ customerEvent.eventInfo.title }}</p>
                    <p class="font-weight-light mb-0">{{ formatDate(customerEvent.decisionDate) }}</p>
                  </h5>
                  <h5
                    v-else
                    class="p-3 mb-0"
                  >
                    <p class="font-weight-medium mb-0">{{ customerEvent.eventInfo.title }}</p>
                    <p class="font-weight-light mb-0">{{ formatDate(customerEvent.decisionDate) }}</p>
                  </h5>
                </a>
              </div>
              <div
                :id="`faq-${customerEvent.eventId}`"
                class="collapse"
              >
                <div class="panel-body block__enento__accordion-body p-3">
                  <p
                    class="mb-0"
                    v-html="customerEvent.eventInfo.information"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Paging
          :items="customerEvents"
          :items-per-page="itemsPerPage"
          :paging-enabled="pagingEnabled"
          @chunk="showSpan"
        />
      </div>
      <div
        v-else
        class="idprotection__noevents mt-4 text-center"
      >
        <i class="far fa-check bg-iceblue color-deepocean mb-2" />
        <p class="idprotection__noevents-text font-weight-medium mb-1">
          Du har inga händelser
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { FormatDate } from '../_helpers.js';
import Paging from './Paging.vue';
import CircleLoader from '../components/CircleLoader.vue';
import Reloader from '../components/Reloader.vue';
import { CUSTOMER_INFO_FETCH } from '../Scripts/store/modules/customerInfoContex';

defineProps({
  itemsPerPage: Number,
  pagingEnabled: Boolean,
  searchEnabled: Boolean
});

const store = useStore();

const customerEvents = computed(() => store.state.customerInfoContex.customerEvents);
const customerEventsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEventsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEventsPending = computed(() => store.state.customerInfoContex.pending);

const filterInputModel = ref('');
const customerEventsChunk = ref([]);

const reFetchCustomerEvents = () => {
  // TODO investigate if we should refetch here
  store.dispatch(CUSTOMER_INFO_FETCH);
}

const formatDateTime = (date) => {
  const re = /-?\d+/;
  const m = re.exec(date);
  const d = new Date(parseInt(m[0]));
  return d.toLocaleDateString('sv-SE');
};

const formatDate = (timestamp) => FormatDate(Number(timestamp));

const notRead = (decisionDate) => {
  const numWeeks = 1;
  const now = new Date();
  now.setDate(now.getDate() - numWeeks * 7);
  return new Date(decisionDate) > now ? "not-read" : "even";
};

const showSpan = (chunk) => {
  customerEventsChunk.value = chunk;
};

const filterResult = () => {
  customerEventsChunk.value = [];
  if (customerEventsLoaded.value) {
    customerEvents.value.forEach(event => {
      const filter = filterInputModel.value.toLowerCase();
      if (event.creditRating && event.creditRating.name.toLowerCase().indexOf(filter) > -1) {
        customerEventsChunk.value.push(event);
      }
      else if (event.creditRatingAttempt && event.creditRatingAttempt.name.toLowerCase().indexOf(filter) > -1) {
        customerEventsChunk.value.push(event);
      }
      else if (event.eventInfo.title.toLowerCase().indexOf(filter) > -1) {
        customerEventsChunk.value.push(event);
      }
      else if (formatDateTime(event.decisionDate).indexOf(filter) > -1) {
        customerEventsChunk.value.push(event);
      }
    })
  }
};
</script>
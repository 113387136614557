<template>
  <div>
    <div
      id="deleteMemberModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delete-member"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header d-flex align-items-baseline">
            <h3
              id="delete-member"
              class="modal-title"
            >
              <i class="far fa-user-times" />Säg upp
            </h3>
            <i
              class="far fa-times hover-pointer"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              Vill du säga upp medlemen 
            </p>
            <p>
              En medlem sägs upp med en månads uppsägningstid och därefter kan du bjuda in någon ny till din grupp. En inbjudan kan tas bort direkt och utan bindningstid.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-enento-secondary  "
              data-dismiss="modal"
            >
              Avbryt
            </button>
            <button
              type="button"
              class="btn btn-enento-feedback-error "
              @click="DeleteGroupMembership()"
            >
              Säg upp
            </button>
          </div>
        </div>
      </div>
    </div>
    <InfoModal
      id="cancel-member" 
      title="Medlem uppsagd"
      body="Medlemen är nu uppsagd och kommer få ett mejl att tjänsten avslutas."
    />
  </div>
</template>
<script>
    import $ from 'jquery';
    import InfoModal from './InfoModal.vue';
export default {
    name: 'DeleteMember',
    components: {
        InfoModal
    },
    props: {
        member: {},
        //memberId: {},
        //ownerId: {}
    },
    data: function(){
        return {
            
        }
    },
    created: function () {
        
    },
    methods: {
        DeleteGroupMembership() {
            var self = this;
            var csrfToken = $("input[name=__RequestVerificationToken]").val();
            $.post("/ajax/CustomerApi/DeleteGroupMembership", {
                memberId: this.member.memberId,
                ownerId: this.member.ownerId,
                __RequestVerificationToken: csrfToken
            }, function (response) {
                if (response.success) {

                    $('#deleteMemberModal').modal('hide');
                    $('#cancel-member').modal('show');
                    self.$parent.GetOwnedUmbrellas();
                }
                else {
                    $("#systemerror").modal('show');
                }
                
            });
        }
    }
}
</script>
<template>
  <VueFinalModal 
    class="error-modal-container"
    content-class="error-modal-content flex flex-col bg-white dark:bg-black rounded border border-gray-100 dark:border-gray-800"
    data-testid="error-modal"
  >
    <div class="error-modal-header d-flex align-items-baseline">
      <h3
        id="info-modal-label"
        class="error-modal-title"
      >
        <i
          v-if="model.iconClass"
          :class="model.iconClass"
        />
        {{ model.errorTitle }}
      </h3>
      <i
        class="far fa-times hover-pointer error-close"
        aria-label="Close"
        @click="emit('confirm')"
      />
    </div>
    <CircleLoader v-if="model.isLoading" />
    <div
      v-else-if="model.errorText"
      class="error-modal-body"
      v-html="model.errorText"
    />
    <div class="modal-slot-content-wrapper">
      <slot />
    </div>
    <div class="error-modal-footer">
      <a
        v-if="model.errorAlreadyBoughtCtaUrl"
        :href="model.errorAlreadyBoughtCtaUrl"
        class="btn btn-default"
      >
        <div
          v-if="model.errorAlreadyBoughtCtaText"
          v-html="model.errorAlreadyBoughtCtaText"
        />
      </a>
      <button
        v-else
        type="button"
        class="btn btn-enento-blue"
        @click="emit('confirm')"
      >
        {{
          model.confirmLabel || 'stäng' }}
      </button>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from 'vue-final-modal';
import CircleLoader from '../CircleLoader.vue';

defineProps({
    model: Object,
});

const emit = defineEmits(['confirm']);
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.modal-slot-content-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 225px);
}

.error-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-modal-content {
    background-color: #fff;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    margin: 1.25rem auto;

    @include breakpoint(small-phone) {
      max-width: 346px;
    }
    @include breakpoint(tablet-portrait) {
      max-width: 450px;
    }
    @include breakpoint(large-desktop) {
      max-width: 528px;
    }

    .error-modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;

        .close {
            padding: 1rem 1rem;
            margin: -1rem -1rem -1rem auto;
        }

        .error-modal-title {
            margin-bottom: 0;
            line-height: 1.5;
        }
        i {
            font-size: 24px;
            color: #333;
        }
    }

    .error-modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }

    .error-modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 0.75rem;
    }
}
</style>

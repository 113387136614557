<template>
  <div class="row">
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Avbetalningskrediter
        </h4>
        <!--<i v-if="model.questionHirePurchase != null && model.questionHirePurchase.value != ''" class="fal fa-lg fa-question-square" v-on:click="hirePurchaseModal=!hirePurchaseModal"></i>-->
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#hirepurchase-question1"
          aria-expanded="false"
          aria-controls="hirepurchase-question1"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>

      <div
        v-if="hasTerminatedHirePurchase"
        class="row"
      >
        <div class="col-12">
          <button
            v-if="showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="fas fa-eye-slash" /> Dölj avslutade
          </button>
          <button
            v-if="!showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="far fa-eye" /> Visa avslutade
          </button>
        </div>
      </div>
      <div
        v-if="hirePurchase.length > 0"
        class="block__enento__accordion__card-wrapper"
      >
        <template v-for="(inquiry,$index) in hirePurchase">
          <div
            v-if="inquiry.toDate == null || showTerminated"
            :key="$index"
            class="block__enento__accordion__card"
          >
            <div class="panel-heading">
              <a
                class="d-block collapsed p-3 pr-5"
                data-toggle="collapse"
                :href="'#downpayment-'+$index"
              >
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>
            <div
              :id="'downpayment-'+$index"
              class="collapse p-3"
            >
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 text-right">
                  {{ NumberFormat(inquiry.grantedCredit) }} kr
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 color-ocean text-right">
                  {{ NumberFormat(inquiry.usedCredit) }} kr
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.fromDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.updatedAtDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 color-red text-right">
                    {{
                      new Date(inquiry.toDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Kreditutveckling
        </h4>
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#hirepurchase-development-question2"
          aria-expanded="false"
          aria-controls="hirepurchase-development-question2"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <HirePurchaseChart
        v-if="report.hirePurchaseData != null"
        :chart-data="report.hirePurchaseData"
      />
      <div class="row">
        <div class="col-md-12">
          <template v-if="report.hirePurchaseData != null">
            <p v-html="model.descriptionHirePurchaseDevelopment.value" />
          </template>
          <template v-else>
            <p v-html="model.descriptionNoHirePurchaseDevelopment.value" />
          </template>
        </div>
      </div>
    </div>
    <InfoModal
      id="hirepurchase-question1"
      :title="model.questionHirePurchaseTitle.value"
      :body="model.questionHirePurchase.value"
    />
    <InfoModal
      id="hirepurchase-development-question2"
      :title="model.questionHirePurchaseDevelopmentTitle.value"
      :body="model.questionHirePurchaseDevelopment.value"
    />
  </div>
</template>
<script>
import { FormatDate,NumberFormat } from '../_helpers.js';
import HirePurchaseChart from '@/components/charts/hirePurchaseChart.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
export default {
    name: 'DownPaymentCredit',
    components: {
        HirePurchaseChart,
        InfoModal
    },
    props: {
        model: {},
        report: {
            creditChartData: null,
            creditReport:null
        }
    },
    data: function(){
        return{
            credits: [],
            showTerminated: true,
            hasTerminatedCredits: false,
            hasTerminatedMortgages: false,
            hasTerminatedHirePurchase: false,
            hasTerminatedUnsecuredLoans: false,
            hirePurchase: Array(),
        }
    },
    created: function () {
        //Tablesaw.init();
        var self = this;
        self.report.creditReport.ctiCreditsWithInquirer.creditInformations.forEach(function (item) {
            //if (self.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] == 1) {
            //    self.credits.push(item);
            //    if (item.toDate != null) self.hasTerminatedCredits = true;
            //}
            if (self.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] == 4) {
                self.hirePurchase.push(item);
                if (item.toDate != null) self.hasTerminatedHirePurchase = true;
            }
        });
        
    },methods: {
        FormatDate: function(timestamp){
            return FormatDate(timestamp);
        },
        NumberFormat(num) {
            return NumberFormat(num);
        }
    }
}
</script>
<template>
  <div class="datamonitor-block js-datamonitor">
    <div
      v-if="customerEssentialsPending"
      class="align-items-center justify-content-center d-flex"
    >
      <NdsLoader />
    </div>
    <Reloader
      v-else-if="customerEssentialsFailed"
      @reload="reFetchCustomerEssentials"
    />
    <div
      v-else-if="customerEssentialsLoaded"
      class="row"
    >
      <div class="col-12">
        <h3
          v-if="model.headline.value"
          class="text"
        >
          {{ model.headline.value }}
        </h3>
        <div
          v-if="model.mainBody.value"
          class="content"
        >
          <p v-html="model.mainBody.value" />
        </div>
        <button
          class="btn btn-enento-blue btn-block mb-2"
          :class="customerEssentials.userHasAccessToDataMonitor && 'js-datamonitor-link'"
          @click="handleAccessToDataMonitorClick"
        >
          <i class="fal fa-external-link mr-2" />
          {{ model.externalServiceUrlDescription.value }}
        </button>
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { NdsLoader } from '@nds/vue';
import Reloader from '../components/Reloader.vue';
import ErrorModal from '../components/modals/ErrorModal.vue';
import EnrollModal from '../components/modals/EnrollModal.vue';
import { defaultGenericErrorModel } from '../components/modals/modalsUtils';
import {
  CUSTOMER_INFO_FETCH,
} from '../Scripts/store/modules/customerInfoContex';


const props = defineProps({
  model: Object,
});
const store = useStore();
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const redirectUrlFailed = computed(() => store.state.customerInfoContex.redirectUrlFailed);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentialsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEssentialsPending = computed(() => store.state.customerInfoContex.pending);
import {
  CUSTOMER_INFO_OPEN_ONLINE_MONITORING,
} from '../Scripts/store/modules/customerInfoContex';


const { open: openError, close: closeError } = useModal({
  component: ErrorModal,
  attrs: {
    model: defaultGenericErrorModel,
    onConfirm() {
      closeError();
    },
  },
});

const { open: openEnroll, close: closeEnroll } = useModal({
  component: EnrollModal,
  attrs: {
    model: {
      title: props.model.modalHeadline.value,
      text: props.model.modalMainBody.value,
      confirmLabel: props.model.modalSubmitButtonText.value,
      modalConditionsLink: props.model.modalConditionsLink.value,
      modalSpinnerText: props.model.modalSpinnerText.value,
      modalErrorMessage: props.model.modalErrorMessage.value,
      monitoringBlockId: props.model.contentLink.id,
    },
    onConfirm() {
      closeEnroll();
    },
  },
});

watch(redirectUrlFailed, () => {
  if (redirectUrlFailed.value) {
    openError();
  }
});

const handleAccessToDataMonitorClick = () => {
  if (customerEssentialsLoaded.value && customerEssentials.value.userHasAccessToDataMonitor) {
    return store.dispatch(CUSTOMER_INFO_OPEN_ONLINE_MONITORING, {
      monitoringBlockId: props.model.contentLink.id,
    });
  }
  return openEnroll();
}

const reFetchCustomerEssentials = () => {
  store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
}
</script>
<template>
  <div
    class="mobile-nav-container"
    data-testid="mobile-menu"
  >
    <div class="d-flex align-items-center pb-3 mobile-menu-header-container">
      <h3 class="creditwatch-title mb-0">
        <RouterLink
          to="/mina-tjanster/kreditkollen/min-ekonomi/krediter"
          class="creditwatch-link"
        >
          Kreditkollen
        </RouterLink>
      </h3>
      <Notifications />
    </div>
    <div
      v-for="(item, index) in menu"
      :key="`menu_mobile_page_${index}`"
      class="mobile-menu-header"
    >
      <div
        v-if="isChildOf(route.path, item.ChildPages)"
        class="mobile-small-menu scroll"
      >
        <ul>
          <li
            v-for="(subchildpage, index) in item.ChildPages"
            :key="`menu_mobile_subchild_page_${index}`"
            :class="subchildpage.Url === route.path ? 'active' : ''"
            :data-test="subchildpage.Name"
          >
            <RouterLink
              :to="subchildpage.Url"
              class="creditwatch-link"
            >
              {{ subchildpage.Name }}
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'
import { computed } from 'vue';
import Notifications from './Notifications.vue';

defineProps({
  currentPage: {}
});
const store = useStore();
const route = useRoute();
const menu = computed(() => store.state.appContext.menu.mobileMenu);


const isChildOf = (currentLink, childPages) => {
  for (const page of childPages) {
    if (page.Url === currentLink) {
      return true;
    }
  }
  return false;
};

</script>
<style scoped lang="scss">
.mobile-menu-header-container {
  margin-right: 12px;
}
</style>
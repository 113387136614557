<template>
  <div class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3" data-testid="dashboard-credits-page">
    <MobileMenu />
    <div class="col-12">
      <div class="dashboard__card">
        <div class="row">
          <div class="col-12 mt-2">
            <h2>{{ model.name }}</h2>
            <p v-html="model.description.value" />
          </div>
          <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
            <NdsLoader />
          </div>
          <div v-else-if="failed" class="col-12">
            <Reloader @reload="getCreditInfo" />
          </div>
          <div v-else class="col-md-12">
            <div class="row">
              <div class="col-md-5">
                <div class="d-flex justify-content-between p-2">
                  <h4 class="mb-0">UC Riskprognos</h4>
                  <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                    @click="openRiskForecastQuestion" aria-label="UC Riskprognos förklaring"/>
                </div>

                <NdsRiskDoughnutChart title="" subTitle="Ditt betyg är" :value="riskGrade" />
                <div v-if="riskGrade === 0" class="col-12">
                  <p v-html="model.descriptionNoScoreGrade.value" />
                </div>
              </div>

              <div class="col-md-7 mt-4 mt-md-0">
                <div class="d-flex justify-content-between p-2">
                  <h4 class="mb-0">
                    Senaste frågor hos UC
                  </h4>
                  <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                    @click="openLatestCreditInquiries"
                    aria-label="Senaste frågor hos UC förklaring" />
                </div>
                <ConsumerInquiries v-if="ctiConsumerInquires" :consumer-inquiries="ctiConsumerInquires" />
                <ZeroState v-else icon="far fa-check" text="Ingen förfrågan registrerad hos UC"
                  data-testid="min-upplysning-general-zerostate" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="pending" class="dashboard__card align-items-center justify-content-center d-flex flex-fill">
        <NdsLoader />
      </div>
      <div v-else-if="!failed" class="dashboard__card pt-0">
        <div class="row">
          <ul class="nav nav-tabs d-flex flex-nowrap w-100 border-0" role="tablist">
            <li class="d-flex nav-item text-nowrap" role="presentation">
              <a href="#profile" class="active" aria-controls="profile" role="tab" data-toggle="tab">Kontokredit</a>
            </li>
            <li class="d-flex nav-item text-nowrap" role="presentation">
              <a href="#messages" aria-controls="messages" role="tab" data-toggle="tab">Avbetalningskredit</a>
            </li>
            <li class="d-flex flex-fill nav-item">
              <a href="#" class="pe-none flex-fill" />
            </li>
          </ul>
          <div class="col-md-12">
            <div class="tab-content py-4">
              <div id="profile" role="tabpanel" class="tab-pane active">
                <CreditCard v-if="customerCreditInfo?.creditReport?.ctiCreditsWithInquirer" :report="customerCreditInfo"
                  :model="model" />
              </div>
              <div id="messages" role="tabpanel" class="tab-pane">
                <DownPaymentCredit v-if="customerCreditInfo?.creditReport?.ctiCreditsWithInquirer"
                  :report="customerCreditInfo" :model="model" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InfoModal id="latest-inquiries-question" title="" :body="model.questionLatestCreditInquiries.value" />
    <RelatedArticles :articles="model.relatedArticles.expandedValue" />
    <MobileBottomMenu />
  </div>
  <ModalsContainer />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ConsumerInquiries from '../components/ConsumerInquiries.vue';
import CreditCard from '../components/CreditCard.vue';
import DownPaymentCredit from '../components/DownPaymentCredit.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import ConfirmModal from '../components/modals/ConfirmModal.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import MobileMenu from '../components/MobileMenu.vue';
import { NdsRiskDoughnutChart, NdsLoader, NdsIconButton } from '@nds/vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import Reloader from '../components/Reloader.vue';

const props = defineProps({
  model: Object
});

const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const riskGrade = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.creditScore?.riskGrade ? store.state.customerCreditInfoContex.customerCreditInfo.creditReport.creditScore.riskGrade * 10 : 0);
const ctiConsumerInquires = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiConsumerInquires?.inquiries?.length > 0 ? store.state.customerCreditInfoContex.customerCreditInfo?.creditReport.ctiConsumerInquires : null);

const { open: openRiskForecastQuestion, close: closeRiskForecastQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'UC Riskprognos',
    icon: 'far fa-question-circle',
    body: props.model.questionRiskForecast.value,
    onConfirm() {
      closeRiskForecastQuestion();
    },
    onCancel() {
      closeRiskForecastQuestion();
    },
  },
});

const { open: openLatestCreditInquiries, close: closeLatestCreditInquiries } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Senaste frågor hos UC',
    icon: 'far fa-question-circle',
    body: props.model.questionLatestCreditInquiries.value,
    onConfirm() {
      closeLatestCreditInquiries();
    },
    onCancel() {
      closeLatestCreditInquiries();
    },
  },
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
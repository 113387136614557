<template>
  <div
    class="container-fluid site-header position-md-sticky"
    data-testid="site-header"
  >
    <div class="site-header__top row">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 d-flex align-items-center px-xl-0">
            <div class="d-md-none">
              <a
                href="/"
                title="Min UC"
                class="navbar-brand site-header__logo"
              >Min UC</a>
            </div>

            <div
              v-if="!isLoggedin"
              class="site-header__top-switch-mobile d-md-none"
            >
              <a
                href="https://www.uc.se"
                class="color-gray"
              >
                <span class="body-xsmall">Företag</span>
              </a>
              <span class="color-gray px-1">|</span>
              <a
                href="/"
                class="color-white"
              >
                <span class="body-xsmall">Privatperson</span>
              </a>
            </div>

            <div class="d-none d-md-inline-flex">
              <a
                href="https://www.uc.se"
                class="site-header__switch-link"
              >Företag</a>
              <a
                href="/"
                class="site-header__switch-link site-header__switch-link--active"
              >Privatperson</a>
            </div>

            <div
              v-if="isLoggedin"
              class="loggedin-top-nav d-inline-flex ml-auto align-items-baseline pr-1"
            >
              <a
                class="btn btn-sm btn-standard d-none d-md-inline-block"
                href="/mina-tjanster"
                data-testid="mina-tjanster-btn"
              >
                <i class="fal fa-user fa-fw mr-1" />
                Mina tjänster
              </a>
              <a
                href="/mina-installningar"
                class="btn btn-sm btn-transparent color-white d-inline-block ml-3"
                data-testid="installningar-heading-btn"
              >
                <span>
                  <i class="far fa-cog fa-fw" />
                </span>
              </a>
              <button
                class="btn btn-sm btn-transparent color-white d-none d-md-inline-block ml-3"
                data-testid="logout-button"
                @click="onLogoutClicked"
              >
                <span class="font-weight-light">
                  Logga ut
                </span>
              </button>
            </div>
            <div
              v-else
              class="loggedin-top-nav d-inline-flex ml-auto align-items-baseline pr-1"
            >
              <a
                class="btn btn-sm btn-standard d-none d-md-inline-block"
                :href="loginLink || 'logga-in'"
                role="button"
                data-testid="login-button"
              >
                Logga in
              </a>
            </div>
            <div class="loggedin-top-nav d-flex align-items-center d-md-none">
              <a
                v-if="!isLoggedin"
                class="btn btn-sm btn-standard"
                :href="loginLink || 'logga-in'"
                role="button"
                data-testid="mobile-login-button"
              >
                <i class="far fa-user-lock fa-fw" />
              </a>
              <a
                class="mobile-menu-bars-button color-white border-0 ml-1 pr-0"
                type="button"
                data-toggle="collapse"
                data-target="#expandableArea"
                aria-expanded="false"
                aria-controls="expandableArea"
              >
                <span
                  class="mobile-menu-hamburger d-flex justify-content-center align-items-center border-0"
                  :class="{ 'is-loggedin': isLoggedin }"
                  data-testid="hamburger-menu-mobile-button"
                >
                  <i class="far fa-bars" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
//import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { clearPersistedStorage } from '../Scripts/store/storeUtils';


const store = useStore();
// const router = useRouter();
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const loginLink = computed(() => store.state.appContext.menu.desktopMenu?.loginLink);

const onLogoutClicked = () => {
    clearPersistedStorage();
    window.location.href = '/Logout';
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.mobile-menu-hamburger {
    gap: 20px;
    width: 56px;
    height: 33px;
    background-color: $dark-ocean;
    border-radius: 100px;

    &.is-loggedin {
        background-color: $orangegold;
    }
}
</style>
<template>
  <div
    class="dashboard col-md-8 col-lg-9 px-0 px-lg-3"
    data-testid="dashboard-my-information-page-new"
  >
    <div class="row mx-0">
      <div class="col-12 d-md-none">
        <RouterLink
          class="return__overview-btn d-flex align-items-center mb-2"
          to="/mina-tjanster"
        >
          <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
          <span class="body-small font-weight-medium">Översikt</span>
        </RouterLink>
      </div>
      <div class="col-12">
        <div class="dashboard__card d-none d-lg-block">
          <div class="row">
            <div class="col-7">
              <h2 class="mb-3">
                {{ model.name }}
              </h2>
              <div
                v-if="customerEssentials.displayCreditWatchProduct && model.descriptionHasCreditWatch"
                v-html="model.descriptionHasCreditWatch.value"
              />
              <div
                v-else-if="!customerEssentials.displayCreditWatchProduct && model.descriptionNoCreditWatch"
                v-html="processedDescription"
              />
              <p v-if="fraudBarrier.FraudBarrierOn">
                Du har begärt att UC inte ska lämna ut några
                uppgifter om dig, detta då din bedrägerispärr är aktiverad. Nedanstående uppgifter visas
                inte för någon annan.
              </p>
            </div>
            <div class="col-md-5 text-right text-small">
              <a
                href="#"
                class="btn btn-enento-blue btn-phone-block btn-showcustomerreport mb-2"
                data-toggle="modal"
                data-target="#createPdf"
                aria-expanded="false"
                aria-controls="createPdf"
              >
                <i class="far fa-file-pdf fa-18 mr-2" />Skapa PDF
              </a>
            </div>
            <ul class="nav nav-tabs d-none d-lg-flex flex-nowrap w-100 mt-3 border-0">
              <li class="d-flex nav-item text-nowrap">
                <a
                  class="active"
                  data-toggle="tab"
                  href="#general-tab"
                  data-testid="min-upplysning-general-tab"
                >Allmänt</a>
              </li>
              <li class="d-flex nav-item text-nowrap">
                <a
                  data-toggle="tab"
                  href="#income-tab"
                  data-testid="min-upplysning-income-tab"
                >Inkomst</a>
              </li>
              <li class="d-flex nav-item text-nowrap">
                <a
                  data-toggle="tab"
                  href="#properties-tab"
                  data-testid="min-upplysning-properties-tab"
                >Fastigheter</a>
              </li>
              <li class="d-flex nav-item text-nowrap">
                <a
                  data-toggle="tab"
                  href="#loans-tab"
                  data-testid="min-upplysning-credits-tab"
                >Krediter</a>
              </li>
              <li class="d-flex nav-item text-nowrap">
                <a
                  data-toggle="tab"
                  href="#remarks-tab"
                  data-testid="min-upplysning-remarks-tab"
                >Anmärkningar</a>
              </li>
              <li class="d-flex nav-item text-nowrap">
                <a
                  data-toggle="tab"
                  href="#inquiries-tab"
                  data-testid="min-upplysning-inquiries-tab"
                >Förfrågningar</a>
              </li>
              <li class="d-flex flex-fill nav-item">
                <a
                  href="#"
                  class="pe-none flex-fill"
                />
              </li>
            </ul>
          </div>
          <div class="tab-content">
            <div
              id="general-tab"
              class="tab-pane active"
            >
              <General
                v-if="customerCreditInfo"
                :report="customerCreditInfo"
              />
            </div>
            <div
              id="income-tab"
              class="tab-pane"
            >
              <Income
                v-if="customerCreditInfo.creditReport"
                :credit-report="customerCreditInfo.creditReport"
                :income-bottom-text="model.incomeBottomText.value"
              />
            </div>
            <div
              id="properties-tab"
              class="tab-pane"
            >
              <Properties
                v-if="customerCreditInfo"
                :report="customerCreditInfo"
              />
            </div>
            <div
              id="loans-tab"
              class="tab-pane"
            >
              <Credits
                v-if="customerCreditInfo"
                :report="customerCreditInfo"
                :intro-text="model.creditText.value"
              />
            </div>
            <div
              id="remarks-tab"
              class="tab-pane"
            >
              <RemarksTab
                v-if="customerCreditInfo"
                :report="customerCreditInfo"
                :intro-text="model.remarksText.value"
                :model="model"
              />
            </div>
            <div
              id="inquiries-tab"
              class="tab-pane"
            >
              <Inquires
                v-if="customerCreditInfo"
                :report="customerCreditInfo"
                :intro-text="model.inquiriesText.value"
              />
            </div>
          </div>
        </div>
        <div class="dashboard__card d-lg-none">
          <div class="row">
            <div class="col-12">
              <h2 class="mb-3">
                {{ model.name }}
              </h2>
              <div
                v-if="customerEssentials.displayCreditWatchProduct && model.descriptionHasCreditWatch"
                v-html="model.descriptionHasCreditWatch.value"
              />
              <div
                v-else-if="!customerEssentials.displayCreditWatchProduct && model.descriptionNoCreditWatch"
                v-html="processedDescription"
              />
              <p v-if="fraudBarrier.FraudBarrierOn">
                Du har begärt att UC inte ska lämna ut några
                uppgifter om dig, detta då din bedrägerispärr är aktiverad. Nedanstående uppgifter visas
                inte för någon annan.
              </p>
            </div>
            <div class="col-12">
              <a
                href="#"
                class="btn btn-enento-blue btn-phone-block btn-showcustomerreport mb-2"
                data-toggle="modal"
                data-target="#createPdf"
                aria-expanded="false"
                aria-controls="createPdf"
              >
                <i class="far fa-file-pdf fa-18 mr-2" />Skapa PDF
              </a>
            </div>
          </div>
        </div>
        <GeneralMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          class="d-lg-none"
        />
        <IncomeMobile
          v-if="customerCreditInfoLoaded"
          :credit-report="customerCreditInfo.creditReport"
          :income-bottom-text="model.incomeBottomText.value"
          class="d-lg-none"
        />
        <PropertiesMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          class="d-lg-none"
        />
        <CreditsMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          :intro-text="model.creditText.value"
          class="d-lg-none"
        />
        <CsnTabMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          :intro-text="model.csnText.value"
          class="d-lg-none"
        />
        <RemarksTabMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          :intro-text="model.remarksText.value"
          :model="model"
          class="d-lg-none"
        />
        <InquiresMobile
          v-if="customerCreditInfoLoaded"
          :report="customerCreditInfo"
          :intro-text="model.inquiriesText.value"
          class="d-lg-none"
        />
      </div>
    </div>
    <RelatedArticles
      v-if="model.relatedArticles.length > 0"
      :articles="model.relatedArticles.expandedValue"
    />
    <div class="row mx-0 d-md-none mt-3">
      <div class="col-12">
        <a
          class="btn btn-enento-secondary w-100 bg-transparent"
          aria-controls="scroll to top"
          @click="onScrollToTop"
        >
          <i class="far fa-long-arrow-alt-up fa-18 mr-3" />Ta mig till toppen av sidan</a>
      </div>
    </div>
  </div>
  <MobileBottomMenu />
  <CreatePdfModal
    id="createPdf"
    title="Skapa din kreditupplysning"
    body="Klicka i de sektioner du vill ha med i din kreditupplysning i PDF-format."
    :report="customerCreditInfo"
  />
</template>
<script setup>
import $ from 'jquery';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted, watch } from 'vue';
import { useWindowScroll } from '@vueuse/core';
import { FormatDateToSwedish } from '../_helpers.js';
import RelatedArticles from '../components/RelatedArticles.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import General from '../components/myinformation/General.vue';
import GeneralMobile from '../components/myinformation/GeneralMobile.vue';
import Income from '../components/myinformation/Income.vue';
import IncomeMobile from '../components/myinformation/IncomeMobile.vue';
import Inquires from '../components/myinformation/Inquiries.vue';
import InquiresMobile from '../components/myinformation/InquiriesMobile.vue';
import Credits from '../components/myinformation/Credits.vue';
import CreditsMobile from '../components/myinformation/CreditsMobile.vue';
import CsnTabMobile from '../components/myinformation/CsnTabMobile.vue';
import Properties from '../components/myinformation/Properties.vue';
import PropertiesMobile from '../components/myinformation/PropertiesMobile.vue';
import RemarksTab from '../components/myinformation/RemarksTab.vue';
import RemarksTabMobile from '../components/myinformation/RemarksTabMobile.vue';
import CreatePdfModal from '../components/modals/CreatePdfModal.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { FRAUD_BARRIER_FETCH } from '../Scripts/store/modules/fraudBarrierContex';


const props = defineProps({
    model: Object
});
const store = useStore();
const route = useRoute();
const { y: windowY } = useWindowScroll({ behavior: 'smooth' });
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const customerCreditInfoLoaded = computed(() => store.state.customerCreditInfoContex.loaded);
const fraudBarrier = computed(() => store.state.fraudBarrierContex.fraudBarrier);
const processedDescription = computed(() => props.model.descriptionNoCreditWatch.value.replace('{0}', `<strong>${getPurchaseDateForProduct('Min Upplysning')}</strong>`));

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";

    // TODO remove jquery...
    const hash = window.location.hash;
    if (hash) {
        $('.nav-tabs a[href="' + hash + '"]').tab('show');
    }

    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }
    if (!store.state.fraudBarrierContex.loaded) {
        store.dispatch(FRAUD_BARRIER_FETCH);
    }
});

watch(route, () => {
    $('.nav-tabs a').removeClass('active');
    $('.nav-tabs a[href="#general-tab"]').addClass('active');
});

const getPurchaseDateForProduct = (productName) => {
    for (const product of customerEssentials.value.purchasedProductPackages) {
        if (product.productPackage.name === productName) {
            const date = new Date(parseInt(product.purchasedDate));
            return FormatDateToSwedish(date);
        }
    }
};

const onScrollToTop = () => {
    windowY.value = 0;
}
</script>
<style lang="scss" scoped>
.text-small {
    font-size: 12px;
}
</style>

<template>
  <div class="dashboard__card p-0 py-3">
    <div class="d-flex align-items-center px-3 mb-3">
      <i class="far fa-shield-alt fa-fw color-nightrider fa-h3-size mr-2" />
      <h3 class="mb-0">
        Bedrägerispärr
      </h3>
    </div>
    <CircleLoader v-if="fraudBarrierPending || fraudBarrierModelPending" />
    <Reloader
      v-else-if="fraudBarrierFailed || fraudBarrierModelFailed"
      @reload="getfraudBarrier"
    />
    <template v-else-if="fraudBarrierLoaded && fraudBarrierModelLoaded">
      <div
        v-if="fraudBarrier.IsFraudBarrierOpen"
        class="d-flex align-items-center px-3 mb-2"
      >
        <span class="font-weight-medium">Bedrägerispärr</span>
        <input
          id="switch"
          v-model="fraudBarrier.FraudBarrierOn"
          type="checkbox"
          class="enento__toggle-input ml-auto"
          @click="setFraudBarrier"
        ><label
          class="enento__toggle mb-0"
          for="switch"
        >
          <i class="far fa-check fade-in-half" />
          <span class="enento__toggle-on">På</span>
          <span class="enento__toggle-off">Av</span>
        </label>
      </div>
      <p
        v-if="fraudBarrier.IsFraudBarrierOpen"
        class="px-3 pt-1"
        v-html="fraudBarrierModel.toggleText.value"
      />
      <p
        v-else
        class="px-3"
        v-html="fraudBarrierModel.erroR_PROTECTION_CLOSED.value"
      />
      <!-- TODO Fix content -->
      <DisableFraudBarrier
        id="disableFraudBarrier"
        :title="fraudBarrierModel.protectionDisableHeadline.value"
        :body="fraudBarrierModel.protectionDisableText.value"
        :fraud-barrier-status="fraudBarrier"
      />
      <EnableFraudBarrier
        id="enableFraudBarrier"
        :title="fraudBarrierModel.protectionEnableHeadline.value"
        :body="fraudBarrierModel.protectionEnableText.value"
        :fraud-barrier-status="fraudBarrier"
      />
      <InfoModal
        id="question1"
        title="Så här fungerar det"
        :body="fraudBarrierModel.howItWorks.value"
      />
    </template>
  </div>
</template>
<script setup>
import DisableFraudBarrier from '../components/modals/DisableFraudBarrier.vue';
import EnableFraudBarrier from '../components/modals/EnableFraudBarrier.vue';
import CircleLoader from '../components/CircleLoader.vue';
import Reloader from '../components/Reloader.vue';
import InfoModal from '../components/modals/InfoModal.vue';
import { FRAUD_BARRIER_FETCH, FRAUD_BARRIER_MODEL_FETCH } from '../Scripts/store/modules/fraudBarrierContex';
import $ from 'jquery';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const store = useStore();
const fraudBarrier = computed(() => store.state.fraudBarrierContex.fraudBarrier);
const fraudBarrierFailed = computed(() => store.state.fraudBarrierContex.failed);
const fraudBarrierPending = computed(() => store.state.fraudBarrierContex.pending);
const fraudBarrierLoaded = computed(() => store.state.fraudBarrierContex.loaded);
const fraudBarrierModel = computed(() => store.state.fraudBarrierContex.fraudBarrierModel);
const fraudBarrierModelFailed = computed(() => store.state.fraudBarrierContex.modelFailed);
const fraudBarrierModelPending = computed(() => store.state.fraudBarrierContex.modelPending);
const fraudBarrierModelLoaded = computed(() => store.state.fraudBarrierContex.modelLoaded);

onMounted(() => {
  if(!store.state.fraudBarrierContex.loaded) {
    store.dispatch(FRAUD_BARRIER_FETCH);
  }
  if(!store.state.fraudBarrierContex.modelLoaded) {
    store.dispatch(FRAUD_BARRIER_MODEL_FETCH);
  }
});

const getfraudBarrier = () => {
    store.dispatch(FRAUD_BARRIER_FETCH);
    store.dispatch(FRAUD_BARRIER_MODEL_FETCH);
}

const setFraudBarrier = (e) => {
    e.preventDefault();
    if (fraudBarrier.value.FraudBarrierOn) {
        return $('#disableFraudBarrier').modal('show');
    } 
    $('#enableFraudBarrier').modal('show');
};

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
    .fraudbarrier__upload-wrapper i {
        left: 11px;
        /* top: calc(50% - 9px); */
        top: 20px;
    }
#generalErrorMessage{
    strong, i.alert-icon{
        color:$feedback-error;
    }
    p{
        color:$emperor;
    }
    strong, p{
        padding-left:35px;
    }
    &.hide{
        display:none;
    }
}
.alert-icon {
    left: 10px;
}
.alert-danger{
    border-radius:0;
}
.enento__toggle {
  margin-right: 30px;
}
</style>
<template>
  <div data-testid="cascade-menu">
    <div
      id="expandableArea"
      class="cascade__menu__mobile-wrapper d-md-none collapse position-absolute"
    >
      <div class="cascade__menu__mobile position-fixed overflow-auto pl-0 w-100">
        <div v-if="menuLoaded">
          <div v-if="isLoggedin">
            <a
              href="#"
              data-toggle="collapse"
              data-target="#my-services-area"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              class="collapsed"
            >
              <div class="cascade__menu__mobile__collapse-header p-4 position-relative">
                <span class="mr-2"><i class="far fa-user fa-fw mr-2" />Mina tjänster</span>
                <span class="color-orangegold font-italic">(inloggad)</span>
              </div>
            </a>
            <div
              id="my-services-area"
              class="collapse show"
              data-testid="cascade-menu-my-services"
            >
              <div
                :class="activeState === '/mina-tjanster' ? 'active p-2' : 'px-2 pb-2'"
                class="cascade__menu__mobile-link pl-5 mx-2 mb-3 color-silver"
                data-testid="cascade-mobile-menu-oversikt-btn"
                @click="goTo('/mina-tjanster')"
              >
                <i class="far fa-home-alt fa-fw mr-2 color-silver" />Översikt
              </div>
              <div
                v-if="customerEssentials.displayIdProtectionProduct"
                :class="activeState === '/mina-tjanster/id-skydd' ? 'active' : ''"
                class="d-flex align-items-center hover-pointer p-2 pl-5 mx-2"
                @click="goTo('/mina-tjanster/id-skydd')"
              >
                <div class="circle-product input-group-text justify-content-center">
                  <i
                    class="fas fa-lock-alt fa-fw color-ocean"
                    data-testid="cascade-mobile-menu-idskydd-btn"
                  />
                </div>
                <span class="menu-item-name color-white">ID-Skydd</span>
              </div>
              <div
                v-if="customerEssentials.displayCreditWatchProduct"
                :class="activeState === '/mina-tjanster/kreditkollen/min-ekonomi/krediter' ? 'active' : ''"
                class="d-flex align-items-center hover-pointer p-2 pl-5 mx-2"
                @click="goTo('/mina-tjanster/kreditkollen/min-ekonomi/krediter')"
              >
                <div class="circle-product input-group-text justify-content-center">
                  <i
                    class="fas fa-analytics fa-fw color-ocean"
                    data-testid="cascade-mobile-menu-kreditkollen-btn"
                  />
                </div>
                <span class="menu-item-name color-white">Kreditkollen</span>
              </div>
              <div
                v-if="customerEssentials.displayCustomerReportProduct"
                :class="activeState === '/mina-tjanster/kreditkollen/min-upplysning' ? 'active' : ''"
                class="d-flex align-items-center hover-pointer p-2 pl-5 mx-2"
                @click="goTo('/mina-tjanster/kreditkollen/min-upplysning')"
              >
                <div class="circle-product input-group-text justify-content-center">
                  <i
                    class="fas fa-lightbulb fa-fw color-ocean"
                    data-testid="cascade-mobile-menu-minupplysning-btn"
                  />
                </div>
                <span class="menu-item-name color-white mr-4">Min Upplysning</span>
                <span
                  v-if="!customerEssentials.displayCreditWatchProduct"
                  class="color-white label-daysleft"
                >
                  {{
                    getEndDateForProduct({
                      productName: 'Min Upplysning', purchasedProductPackages:
                        customerEssentials.purchasedProductPackages
                    }) }} dagar
                </span>
              </div>
              <div
                v-if="customerEssentials.displayCreditScoreProduct"
                :class="activeState === '/mina-tjanster/mitt-kreditbetyg' ? 'active' : ''"
                class="d-flex align-items-center hover-pointer p-2 pl-5 mx-2"
                @click="goTo('/mina-tjanster/mitt-kreditbetyg')"
              >
                <div class="circle-product input-group-text justify-content-center">
                  <i class="fas fa-tachometer fa-fw color-ocean" />
                </div>
                <span
                  class="menu-item-name color-white"
                  data-testid="cascade-mobile-menu-kreditbetyg-btn"
                >Mitt
                  Kreditbetyg</span>
              </div>
              <div
                :class="activeState === '/mina-tjanster/bedragerisparr' ? 'active' : ''"
                class="cascade__menu__mobile-link p-2 pl-5 mt-3 mb-2 mx-2 color-silver"
                @click="goTo('/mina-tjanster/bedragerisparr')"
              >
                <i
                  class="fas fa-shield-alt fa-fw fa-fw mr-2"
                  data-testid="cascade-mobile-menu-bedragerisparr-btn"
                />
                <span>Bedrägerispärr</span>
              </div>
              <div
                :class="activeState === '/mina-tjanster/digital-brevlada' ? 'active' : ''"
                class="cascade__menu__mobile-link p-2 pl-5 mx-2 mb-3 color-silver"
                @click="goTo('/mina-tjanster/digital-brevlada')"
              >
                <i
                  class="fas fa-envelope fa-fw fa-fw mr-2"
                  data-testid="cascade-mobile-menu-brevlada-btn"
                />
                <span>Digital Brevlåda</span>
              </div>
            </div>
          </div>
          <div
            class="cascade__menu__mobile-border"
            data-testid="cascade-menu-products-and-services"
          >
            <a
              href="#"
              data-toggle="collapse"
              data-target="#products-area"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              class="collapsed"
            >
              <div class="cascade__menu__mobile__collapse-header p-4 position-relative">
                <span><i class="far fa-globe fa-fw mr-2" />Produkter & tjänster</span>
              </div>
            </a>
            <div
              id="products-area"
              class="container-fluid collapse collapsable-area py-3"
            >
              <div class="row">
                <div class="col-6">
                  <p class="color-white">
                    {{ cascadeMenu.products.first.title }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="color-white">
                    {{ cascadeMenu.products.second.title }}
                  </p>
                </div>
              </div>
              <div class="row row-flex">
                <div class="col-6 px-0">
                  <div
                    v-for="(childPage, index) in cascadeMenu.products.first.menuItems"
                    :key="`cascade_menu_mobile_item_first_${index}`"
                    class="px-1 mb-2"
                  >
                    <div class="cascade__menu__mobile__card">
                      <a
                        :href="childPage.url"
                        class="p-3"
                      >
                        <i
                          :class="childPage.fontAwesomeClass"
                          class="fa-fw color-white mr-2"
                        />
                        <h5 class="d-inline-block color-white">{{ childPage.title }}</h5>
                        <p class="body-xsmall mb-0">{{ childPage.teaserText }}</p>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 px-0">
                  <div
                    v-for="(childPage, index) in cascadeMenu.products.second.menuItems"
                    :key="`cascade_menu_mobile_item_second_${index}`"
                    class="px-1 cascade__menu__mobile__card-wrapper mb-2"
                  >
                    <div class="cascade__menu__mobile__card">
                      <a
                        :href="childPage.url"
                        class="p-3"
                      >
                        <i
                          :class="childPage.fontAwesomeClass"
                          class="fa-fw color-white mr-2"
                        />
                        <h5 class="d-inline-block color-white">{{ childPage.title }}</h5>
                        <p class="body-xsmall mb-0">{{ childPage.teaserText }}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="cascade__menu__mobile-border"
            data-testid="cascade-menu-articles"
          >
            <a
              href="#"
              data-toggle="collapse"
              data-target="#articles-area"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              class="collapsed"
            >
              <div class="cascade__menu__mobile__collapse-header p-4 position-relative">
                <span><i class="far fa-book fa-fw mr-2" />Artiklar & Kunskap</span>
              </div>
            </a>
            <div
              id="articles-area"
              class="container-fluid collapse collapsable-area py-3"
            >
              <div
                v-if="cascadeMenu.category.title"
                class="row"
              >
                <div class="col-6">
                  <p class="color-white">
                    {{ cascadeMenu.category.title }}
                  </p>
                </div>
              </div>
              <div class="row row-flex">
                <div
                  v-for="(item, index) in cascadeMenu.category.menuItems"
                  :key="`cascade_menu_mobile_category_item_${index}`"
                  class="col-6 cascade__menu__mobile__card-wrapper mb-2"
                >
                  <div class="cascade__menu__mobile__card">
                    <a
                      :href="item.url"
                      class="p-3"
                    >
                      <h5 class="d-inline-block color-white mb-0">
                        <i
                          :class="item.fontAwesomeClass"
                          class="fa-fw color-white mr-2"
                        />
                        {{ item.title }}
                      </h5>
                      <p class="body-xsmall mb-0">{{ item.teaserText }}</p>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="color-white py-3 pl-3 mb-3">
                    {{ cascadeMenu.article.title }}
                  </p>
                  <div
                    v-for="(item, index) in cascadeMenu.article.menuItems"
                    :key="`cascade_menu_mobile_article_item_${index}`"
                    class="pl-5 mb-3"
                  >
                    <a :href="item.url">
                      <span class="color-gallery">{{ item.title }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cascade__menu__mobile-border">
            <a
              :href="cascadeMenu.helpLink"
              class="p-4"
            >
              <span><i class="fas fa-life-ring fa-fw mr-2" />Hjälp & Kontakt</span>
            </a>
          </div>
          <div
            v-if="isLoggedin"
            :class="activeState === '/mina-installningar' ? 'active mb-2' : ''"
            class="cascade__menu__mobile-border"
          >
            <div
              class="cascade__menu__mobile-link p-4 color-silver"
              @click="goTo('/mina-installningar')"
            >
              <i
                class="far fa-cog fa-fw mr-2"
                data-testid="cascade-mobile-menu-installningar-btn"
              />
              <span>Inställningar</span>
            </div>
          </div>
          <div
            v-if="isLoggedin"
            class="py-2 px-4"
          >
            <button
              class="btn btn-sm btn-transparent"
              data-testid="cascade-mobile-menu-logout-btn"
              @click="onLogoutClicked"
            >
              <span class="color-white">Logga ut</span>
            </button>
          </div>
          <div
            v-else
            class="py-2 px-4"
          >
            <a
              class="btn btn-sm btn-default"
              href="/logga-in"
              role="button"
              data-testid="cascade-mobile-menu-login-btn"
            ><span class="color-white">Logga in</span></a>
          </div>
        </div>
        <div
          v-else-if="menuPending"
          class="align-items-center justify-content-center d-flex"
        >
          <NdsLoader />
        </div>
        <Reloader
          v-else-if="menuFailed"
          @reload="getMenu"
        />
      </div>
    </div>
    <div
      id="cascade__menu-top"
      class="container-xl d-none d-md-block"
    >
      <div class="row py-4 cascade__menu-top">
        <div class="col-12 d-flex px-xl-0">
          <a
            href="/"
            title="Min UC"
            class="navbar-brand site-header__logo"
          >Min UC</a>
          <div class="col-7 cascade__menu-trigger">
            <ul
              id="pills-tab"
              class="nav nav-pills col-12"
              role="tablist"
            >
              <li class="nav-item mr-4">
                <a
                  id="pills-products-and-services-tab"
                  class="nav-link position-relative pr-4"
                  data-toggle="pill"
                  href="#pills-products-and-services"
                  role="tab"
                  aria-controls="pills-products-and-services"
                  aria-selected="true"
                >
                  <span class="pb-1">Produkter & tjänster</span>
                </a>
              </li>
              <li class="nav-item pr-0">
                <a
                  id="pills-articles-tab"
                  class="nav-link position-relative pr-4"
                  data-toggle="pill"
                  href="#pills-articles"
                  role="tab"
                  aria-controls="pills-articles"
                  aria-selected="false"
                >
                  <span class="pb-1">Artiklar & kunskap</span>
                </a>
              </li>
            </ul>
          </div>
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item">
              <a
                :href="cascadeMenu.helpLink"
                class="nav-link cascade-hide pr-0"
              >
                <div class="d-flex align-items-center">
                  <i class="fas fa-life-ring fa-lg fa-fw" />
                  <span class="color-silver">Hjälp & kontakt</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="cascade__menu-expanded"
      class="cascade__menu-expanded d-none d-sm-block position-absolute w-100"
    >
      <div
        v-if="menuLoaded"
        class="container"
      >
        <div class="row tab-content justify-content-center">
          <div
            id="pills-products-and-services"
            class="col-12 tab-pane fade-in-half py-4"
            role="tabpanel"
            aria-labelledby="pills-products-and-services-tab"
          >
            <div class="row">
              <div class="col-6 col-lg-3">
                <h3 class="color-white mb-4">
                  {{ cascadeMenu.products.first.title }}
                </h3>
                <ul class="cascade__menu-list pl-0">
                  <a
                    v-for="(menuItem, index) in cascadeMenu.products.first.menuItems"
                    :key="`cascade_menu_first_item_${index}`"
                    :href="menuItem.url"
                    class="list-item"
                  >
                    <li class="d-flex flex-wrap">
                      <span class="input-group-text border-0 bg-transparent pl-0">
                        <i
                          :class="menuItem.fontAwesomeClass"
                          class="fa-fw"
                        />
                      </span>
                      <h4 class="color-white mb-0"><span class="mb-2">{{ menuItem.title }}</span></h4>
                      <p class="color-silver mt-1 pl-4 ml-2">{{ menuItem.teaserText }}</p>
                    </li>
                  </a>
                </ul>
              </div>
              <div class="col-6 col-lg-3 offset-lg-1">
                <h3 class="color-white mb-4">
                  {{ cascadeMenu.products.second.title }}
                </h3>
                <ul class="cascade__menu-list pl-0">
                  <a
                    v-for="(menuItem, index) in cascadeMenu.products.second.menuItems"
                    :key="`cascade_menu_second_item_${index}`"
                    :href="menuItem.url"
                    class="list-item"
                  >
                    <li class="d-flex flex-wrap">
                      <span class="input-group-text justify-content-center border-0 bg-transparent pl-0">
                        <i
                          :class="menuItem.fontAwesomeClass"
                          class="fa-fw"
                        />
                      </span>
                      <h4 class="color-white mb-0"><span class="mb-2">{{ menuItem.title }}</span></h4>
                      <p class="color-silver mt-1 pl-4 ml-2">{{ menuItem.teaserText }}</p>
                    </li>
                  </a>
                </ul>
              </div>

              <div
                v-if="cascadeMenu.teaserImages.first.length > 0"
                class="teaser-image-continer col-6 offset-3 col-lg-4 offset-lg-1 d-none d-lg-block"
              >
                <FullImageCardBlock :model="cascadeMenu.teaserImages.first[0]" />
              </div>
            </div>
          </div>
          <div
            id="pills-articles"
            class="col-12 tab-pane fade-in-half pt-4 pb-5"
            role="tabpanel"
            aria-labelledby="pills-articles-tab"
          >
            <div class="row">
              <div class="col-6 col-lg-3">
                <h3 class="color-white mb-4">
                  {{ cascadeMenu.category.title }}
                </h3>
                <ul class="cascade__menu-list pl-0">
                  <a
                    v-for="(item, index) in cascadeMenu.category.menuItems"
                    :key="`cascade_menu_category_item_${index}`"
                    :href="item.url"
                    class="list-item d-flex "
                  >
                    <li class="d-flex w-100 mb-4">
                      <span class="input-group-text justify-content-center border-0 bg-transparent pl-0">
                        <i
                          :class="item.fontAwesomeClass"
                          class="fa-fw"
                        />
                      </span>
                      <h4 class="color-white w-100 mb-0"><span class="mb-1">{{ item.title }}</span></h4>
                      <p class="color-white">{{ item.teaserText }}</p>
                    </li>
                  </a>
                </ul>
              </div>
              <div class="col-6 col-lg-4">
                <h3 class="color-white mb-4">
                  {{ cascadeMenu.article.title }}
                </h3>
                <ul class="cascade__menu-list pl-0">
                  <li
                    v-for="(item, index) in cascadeMenu.article.menuItems"
                    :key="`cascade_menu_article_item_${index}`"
                    class="list-item mb-2"
                  >
                    <a
                      :href="item.url"
                      class="d-flex"
                    >
                      <h5 class="color-white">{{ item.title }}</h5>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                v-if="cascadeMenu.teaserImages.second.length > 0"
                class="teaser-image-continer col-6 offset-3 col-lg-4 offset-lg-1 d-none d-lg-block"
              >
                <FullImageCardBlock :model="cascadeMenu.teaserImages.second[0]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="menuPending"
        class="align-items-center justify-content-center d-flex"
      >
        <NdsLoader />
      </div>
      <Reloader
        v-else-if="menuFailed"
        @reload="getMenu"
      />
    </div>
  </div>
</template>
<script setup>
import FullImageCardBlock from './blocks/FullImageCardBlock.vue';
import { NdsLoader } from '@nds/vue';
import Reloader from '../components/Reloader.vue';
import { computed, ref, onMounted } from 'vue';
import $ from 'jquery';
//import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getEndDateForProduct } from '../utils/productUtils';
import { clearPersistedStorage } from '../Scripts/store/storeUtils';
import { APP_FETCH_MENU } from '../Scripts/store/modules/appContext';

const store = useStore();
// const router = useRouter();
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const cascadeMenu = computed(() => store.state.appContext.menu.desktopMenu);
const menuLoaded = computed(() => store.state.appContext.menuLoaded);
const menuPending = computed(() => store.state.appContext.menuPending);
const menuFailed = computed(() => store.state.appContext.menuFailed);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const activeState = ref();

onMounted(() => {
  // TODO remove this and handle it differently without jquerry
  $('#expandableArea').on('hidden.bs.collapse', function () {
    $('body').removeClass('overflow-hidden');
    activeState.value = window.location.pathname;
  });
  $('#expandableArea').on('shown.bs.collapse', function () {
    $('body').addClass('overflow-hidden');
    activeState.value = window.location.pathname;
  });
});

const goTo = (url) => {
  $('.mobile-menu-bars-button').trigger('click');

  // TODO switch to using router when routs are configured
 // router.push({ path: url });

  location.href = url;
};

const onLogoutClicked = () => {
  clearPersistedStorage();
  window.location.href = '/Logout';
}

const getMenu = () => {
  store.dispatch(APP_FETCH_MENU);
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_mixins.scss";

.cascade__menu__mobile-wrapper {
  z-index: 20;

  ul {
    list-style-type: none;
  }

  .cascade__menu__mobile {
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
    background-color: $night-rider;
    top: 57px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    .active {
      border-radius: 8px;
      background-color: $emperor-hover;
    }

    .cascade__menu__mobile-border {
      border-top: 1px solid $emperor;
    }

    .cascade__menu__mobile-link {
      @include attention() {
        cursor: pointer;
      }
    }

    .cascade__menu__mobile__collapse-header {
      span:first-child {
        color: $white;

        i {
          color: $orangegold;

          &.fas {
            font-size: 1rem;
          }
        }
      }

      &:after {
        font-family: "Font Awesome 5 Pro";
        content: '\f077';
        color: $white;
        position: absolute;
        right: 25px;
        top: calc(50% - 13px);
      }
    }

    .collapsed {
      .cascade__menu__mobile__collapse-header {
        span:first-child {
          color: $silver;

          i {
            color: $silver;
          }
        }

        &:after {
          color: $silver;
          content: '\f078';
        }
      }
    }


    a {
      text-decoration: unset;
      color: $silver;

      .btn {
        color: $white;
        border-radius: 8px;
        margin-left: 1rem;
      }
    }

    a:not(.btn) {
      display: block;
      width: 100%;
    }
  }

  .cascade__menu__mobile__card-wrapper {
    padding-left: 0.25rem;
    padding-right: 0.5rem;
  }

  .cascade__menu__mobile__card {
    border: 1px solid $emperor;
    border-radius: 6px;

    p {
      font-size: 12px;
      color: $silver;
    }
  }

}
</style>
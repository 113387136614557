<template>
  <div
    id="idprotectionpage"
    class="dashboard col-md-8 col-lg-9"
    data-testid="dashboard-id-protection-page"
  >
    <div
      v-if="!customerEventsExpanded"
      class="row"
    >
      <div class="col-12 d-md-none">
        <RouterLink
          class="return__overview-btn d-flex align-items-center mb-2"
          to="/mina-tjanster"
        >
          <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
          <span class="body-small">Översikt</span>
        </RouterLink>
      </div>
      <div class="col-lg-7">
        <div class="dashboard__card pb-0">
          <h2 class="mb-3">
            {{ model.name }}
          </h2>
          <div
            v-if="umbrellaProductId"
            class="d-flex align-items-baseline mb-3"
          >
            <i class="far fa-users fa-sm fa-fw color-darkocean py-1 mr-1" />
            <RouterLink
              to="/mina-installningar#members-tab"
              class="input-group-text font-weight-light position-relative p-0 border-0 bg-transparent"
            >
              <u>Hantera mina medlemmar</u>
            </RouterLink>
          </div>
          <div
            v-if="model.importantDocuments !== null"
            id="idprotection-accordion"
            class="block__enento__accordion"
          >
            <div class="panel-group">
              <div class="panel-heading">
                <a
                  class="d-block collapsed m-negative"
                  data-toggle="collapse"
                  href="#idprotection-0"
                  data-testid="accordion-idprotection"
                >
                  <h5 class="font-weight-medium p-3 mb-0">
                    Viktiga dokument för ditt ID-Skydd
                  </h5>
                </a>
              </div>
              <div
                id="idprotection-0"
                class="collapse"
                data-parent="#idprotection-accordion"
              >
                <div class="panel-body block__enento__accordion-body py-3">
                  <a
                    v-for="(document, index) in model.importantDocuments.value"
                    :key="`id_protection_link_${index}`"
                    :href="document.href"
                    target="_blank"
                    :class="document.href.indexOf('.pdf') > -1 ? 'accordion-pdf' : 'accordion-link'"
                    class="d-block font-weight-light mb-2"
                  >{{ document.text }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard__card">
          <div class="d-flex align-items-center mb-3">
            <h3 class="mb-0">
              UC Bevakning
            </h3>
          </div>
          <CustomerEvents
            :items-per-page="5"
            :paging-enabled="false"
            :search-enabled="false"
          />
        </div>
      </div>
      <div class="col-lg-5">
        <div class="dashboard__card">
          <div class="d-flex align-items-baseline mb-3">
            <i class="fas fa-hand-paper fa-fw color-nightrider fa-h3-size mr-2" />
            <h3 class="mb-0">
              Blivit ID-kapad?
            </h3>
          </div>
          <p class="color-emperor mt-3 mb-2">
            Vi hjälper vi dig dygnet runt via vår assistans. Du når assistansen på telefonnumret
          </p>
          <a
            href="tel:0771-20 25 20"
            class="text-decoration-none text-center"
          >
            <p class="font-weight-medium phone-large mb-0">0771-20 25 20</p>
          </a>
        </div>
        <div
          v-if="onlineMonitoringModel"
          class="dashboard__card"
        >
          <div class="d-flex align-items-baseline mb-3">
            <i class="far fa-globe fa-fw color-nightrider fa-h3-size mr-2" />
            <h3 class="mb-0">
              Onlinebevakning
            </h3>
          </div>
          <p v-html="model.onlinesurveillanceText.value" />
          <OnlineMonitoring :model="onlineMonitoringModel" />
        </div>
        <div
          v-if="model.insuranceInfoText != null"
          class="dashboard__card"
        >
          <div class="d-flex align-items-baseline mb-3">
            <i class="fas fa-life-ring fa-fw color-nightrider fa-h3-size mr-2" />
            <h3 class="mb-0">
              Din försäkring
            </h3>
          </div>
          <p v-html="model.insuranceInfoText.value" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-12 return__overview-icon">
        <i
          class="far fa-arrow-left hover-pointer"
          @click="onToggleExpandCustomerEvents"
        />
      </div>
      <div class="col-lg-9">
        <div class="dashboard__card">
          <div class="d-flex align-items-baseline mb-3">
            <i class="far fa-eye fa-fw color-nightrider fa-h3-size mr-2" />
            <h3 class="mb-0">
              UC Bevakning
            </h3>
          </div>
          <CustomerEvents
            :items-per-page="8"
            :paging-enabled="true"
            :search-enabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import CustomerEvents from '../components/CustomerEvents.vue';
import OnlineMonitoring from '../components/OnlineMonitoring.vue';

const props = defineProps({
  model: Object,
  smallTitle: Boolean
});
const store = useStore();
const router = useRouter();

const onlineMonitoringModel = computed(() => props.model.mainContentArea.expandedValue.find((item) => item.contentType.includes("DataMonitorBlock")));
const umbrellaProductId = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId);
const customerEventsExpanded = ref(false);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (router.currentRoute.value.query.all) {
    customerEventsExpanded.value = true;
  }
});

const onToggleExpandCustomerEvents = () => {
  customerEventsExpanded.value = !customerEventsExpanded.value;
}
</script>
<style scoped lang="scss">
@import "@/../../styles/abstracts/_breakpoints.scss";

.phone-large {
  font-family: Gilroy-Bold;
  font-size: 2em;
  line-height: 2.5rem;

  @include breakpoint(max-phone) {
    font-size: 1.75em;
    line-height: 2rem;
  }
}

.block__enento__accordion .panel-group {
  border-bottom: none;

  .panel-heading a {

    &.collapsed:hover h5 {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:after {
      right: 0;
    }
  }
}
</style>
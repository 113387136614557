<template>
  <div
    id="digitalmailboxpage"
    class="dashboard col-md-8 col-lg-9"
    data-testid="dashboard-digital-mailbox-page"
  >
    <div class="row">
      <div class="col-12 d-md-none">
        <RouterLink
          class="return__overview-btn d-flex align-items-center mb-2"
          to="/mina-tjanster"
        >
          <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
          <span class="body-small">Översikt</span>
        </RouterLink>
      </div>
      <div class="col-12">
        <div class="dashboard__card">
          <div class="information-container">
            <h2 class="mb-4">
              {{ model.name }}
            </h2>
            <Reloader
              v-if="failed"
              @reload="fetchDigitalMailBoxMessages"
            />
            <div
              v-else-if="pending"
              class="align-items-center justify-content-center d-flex"
            >
              <NdsLoader />
            </div>
            <template v-else>
              <div class="d-flex align-items-center mb-4">
                <span class="font-weight-medium">Status</span>
                <input
                  id="switch"
                  v-model="isActive"
                  type="checkbox"
                  class="enento__toggle-input"
                  @change="toggleDigitalMailbox"
                >
                <label
                  class="enento__toggle mb-0 ml-auto ml-md-0"
                  for="switch"
                  data-testid="digital-brevlada-page-switch-btn"
                >
                  <i class="far fa-check fade-in-half" />
                  <span class="enento__toggle-on">På</span>
                  <span class="enento__toggle-off">Av</span>
                </label>
              </div>
              <p
                v-if="isActive"
                class="mb-4"
                v-html="model.descriptionWhenActivated.value"
              />
              <DigitalMailBoxMessages
                v-if="isActive"
                :messages="messages"
                :paging-enabled="true"
                :items-per-page="10"
              />
              <p
                v-else
                class="mb-4"
                v-html="model.descriptionWhenDisabled.value"
              />
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="isActive"
        class="col-12"
      >
        <div class="dashboard__card">
          <div
            class="information-container"
            data-testid="digital-brevlada-page-about-digital-brevlada-container"
          >
            <h3>Om Digital Brevlåda</h3>
            <span v-html="model.aboutText.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import DigitalMailBoxMessages from '../components/DigitalMailBoxMessages.vue';
import { NdsLoader } from '@nds/vue';
import Reloader from '../components/Reloader.vue';
import ErrorModal from '../components/modals/ErrorModal.vue';
import { defaultGenericErrorModel } from '../components/modals/modalsUtils';
import { getGetDigitalMailBoxMessages, postSetDigitalMailBoxActive } from '../Scripts/providers/frontendApiControllerProviders';

const props = defineProps({
    model: Object,
});

const messages = ref([]);
const isActive = ref(false);
const failed = ref(false);
const pending = ref(false);
const errorModel = ref(defaultGenericErrorModel);

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    fetchDigitalMailBoxMessages();
});

const { open, close } = useModal({
    component: ErrorModal,
    attrs: {
        model: errorModel,
        onConfirm() {
            close();
        },
    },
});

const fetchDigitalMailBoxMessages = () => {
    failed.value = false;
    pending.value = true;
    isActive.value = false;
    getGetDigitalMailBoxMessages().then(response => {
        messages.value = response.data?.MessageList ? response.data.MessageList : [];
        isActive.value = Boolean(response.data?.Activated);
        failed.value = false;
        pending.value = false;
    }).catch(() => {
        messages.value = [];
        isActive.value = false;
        failed.value = true;
        pending.value = false;
    })
}

const toggleDigitalMailbox = () => {
    postSetDigitalMailBoxActive({ setActive: !isActive.value ? "OFF" : "ON" }).then(() => {
        fetchDigitalMailBoxMessages();
    }).catch(() => {
        open();
    })
}
</script>

<template>
  <button
    v-if="(members.length + invitations.length) < umbrella.size"
    type="button"
    class="btn btn-enento-blue mt-3 mt-md-5 mb-1 mr-auto col-12 col-md-3"
    data-toggle="modal"
    data-target="#inviteModal"
  >
    Bjud in medlem
  </button>
  <div
    id="inviteModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="invite-member"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header d-flex align-items-baseline">
          <h3
            id="invite-member"
            class="modal-title"
          >
            <i class="far fa-user" />Bjud in medlem
          </h3>
          <i
            class="far fa-times hover-pointer"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p>
            Fyll i namn och epostadress till personen du vill bjuda in. Inbjudan skickas via epost. Det kommer att framgå tydligt i inbjudan vem den kommer ifrån.
          </p>
          <div class="form-group">
            <label for="email">E-postadress</label>
            <input
              id="email" 
              v-model="v$.email.$model" 
              type="email"
              class="form-control"
              :class="v$.email.$errors.length > 0 ? 'is-invalid':''"
              aria-describedby="emailHelp"
            >
            <template v-if="v$.email.$errors.length > 0">
              <small
                v-if="v$.email.email.$invalid"
                class="is-invalid"
              >{{ v$.email.email.$message }}</small>
              <small
                v-else-if="v$.email.required.$invalid"
                class="is-invalid"
              >{{ v$.email.required.$message }}</small>
            </template>
          </div>
          <div class="form-group">
            <label for="name">Namn</label>
            <input
              id="name"
              v-model="v$.name.$model"
              type="text"
              class="form-control"
              :class="v$.name.$errors.length > 0 ? 'is-invalid':''"
            >
            <template v-if="v$.name.$errors.length > 0">
              <small
                v-if="v$.name.required.$invalid"
                class="is-invalid"
              >{{ v$.name.required.$message }}</small>
              <small
                v-else-if="v$.name.valid_name.$invalid"
                class="is-invalid"
              >{{ v$.name.valid_name.$message }}</small>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-enento-secondary  "
            data-dismiss="modal"
          >
            Avbryt
          </button>
          <button
            type="button"
            :disabled="v$.$error"
            class="btn btn-enento-blue "
            @click="SendGroupInvite()"
          >
            Bjud in
          </button>
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    id="info-1"
    title="Inbjudan skickad"
    icon="far fa-check-circle"
    body="Inbjudan är skickad!
Om mottagaren inte får ett mejl, be dem kolla sin skräppost."
  />
  <InfoModal
    id="info-error"
    title="Inbjudan skickad"
    body="Någonting gick fel"
  />
</template>
<script>
    import $ from 'jquery';
    import InfoModal from './InfoModal.vue';
    import useValidate from '@vuelidate/core'
    import { required, email, helpers } from '@vuelidate/validators'
    import { isValidName } from '@/utils/validators.js';
    
export default {
    name: 'InviteMember',
    components: {
        InfoModal
    },
    props: {
        groupId: Number,
        invitations: Array,
        members: Array,
        umbrella:Object
    },
    data: function(){
        return {
            v$: useValidate(),
            email: "",
            name: ""
        }
    },
    mounted: function () {
        var self = this;
        $("#inviteModal").on("hidden.bs.modal", function () {
            // put your default event here
            if (self.email == "") self.v$.email.$reset();
            if (self.name == "") self.v$.name.$reset();
        });
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage('Vänligen fyll i detta fält', required),
                email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email)
            },
            name: {
                valid_name: {
                    $validator: isValidName,
                    $message: "Namn fältet innehåller ogiltiga tecken"
                },
                required: helpers.withMessage('Vänligen fyll i detta fält', required),
                //alpha: helpers.withMessage('Namn fältet innehåller ogiltiga tecken', alpha)
            }
        }
    },
    methods: {
        Validate(){
            this.v$.$validate();
        },
        SendGroupInvite() {
            this.v$.$validate()
            if (!this.v$.$error) {
                var self = this;
                var csrfToken = $("input[name=__RequestVerificationToken]").val();
                $.post("/ajax/CustomerApi/SendGroupInvite", {
                    groupId: self.groupId,
                    name: self.name,
                    email: self.email,
                    __RequestVerificationToken: csrfToken
                },
                    function (response) {
                        
                        $('#inviteModal').modal('hide');
                        self.email = "";
                        self.name = "";
                        self.v$.email.$reset();
                        self.v$.name.$reset();
                        if (response.success) {
                            $('#info-1').modal('show');
                            self.$parent.GetOwnedUmbrellas();
                        }
                        else {
                            $('#info-error').modal('show');
                        }
                    });
            } 
        }
    }
}
</script>
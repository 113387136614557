
import { personalIdentityNumberRule } from './personalIdentityNumber';
import { coordinationNumberRule } from './coordinationNumber';

export const isValidSwedishCellPhone = (number) => {
    const validNamePattern = /^07[02369]\d{7}$/g;
    if (validNamePattern.exec(number)) {
        return true;
    }
    return false;
}

export const isValidSocialSecNumber = (number) => {
    return personalIdentityNumberRule(number);
}

export const isValidName = (name) => {
    const validNamePattern = /^[^\s]+[a-zA-ZåäöÅÄÖ_\s-]{0,30}$/g;
    if (validNamePattern.exec(name)) {
        return true;
    }
    return false;
}

export const isValidCoordinationNumber = (number) => {
    if (coordinationNumberRule(number))
    {     
        return false;
    }
    return true;
}

// TODO replace with email rules from formik
export const emailRule = (value) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/;
    return emailPattern.test(value);
}

export const postalCodeRule = (value) => {
    const postalCodePattern = /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/;
    return postalCodePattern.test(value);
}

export const isValidSwedishPostalCode = (number) => {
    const postalCodePattern = /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/;
    if (postalCodePattern.exec(number)) {
        return true;
    }
    return false;
}

<template>
  <div
    class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3"
    data-testid="dashboard-income-and-taxation-page"
  >
    <MobileMenu />
    <div class="dashboard__card">
      <div class="row">
        <div class="col-12 mt-2 d-md-none">
          <RouterLink
            class="return__overview-btn d-flex align-items-center mb-2"
            to="/mina-tjanster"
          >
            <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
            <span class="body-small font-weight-medium">Översikt</span>
          </RouterLink>
        </div>
        <div class="col-12 mt-2">
          <h2>{{ model.name }}</h2>
          <p v-html="model.description.value" />
        </div>
        <div class="col-12">
          <IncomeAndTaxation
            v-if="customerCreditInfo.creditReport?.ctiConsumerIncomeAndTaxation"
            :cti-consumer-income-and-taxation="customerCreditInfo.creditReport?.ctiConsumerIncomeAndTaxation"
            :sole-trader="customerCreditInfo.creditReport.extendedConsumerInformation.soleTrader"
          />
        </div>
        <div
          v-if="!customerCreditInfo.creditReport?.ctiConsumerIncomeAndTaxation"
          class="col-12 mt-5"
        >
          <p v-html="model.noIncomeAndTaxation.value" />
        </div>
      </div>
      <span
        class="body-small"
        v-html="model.incomeBottomText.value"
      />
    </div>
    <RelatedArticles
      v-if="model.relatedArticles.length > 0"
      :articles="model.relatedArticles.expandedValue"
    />
    <MobileBottomMenu />
  </div>
</template>

<script setup>
import IncomeAndTaxation from '../components/incomeAndTaxation/IncomeAndTaxation.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const props = defineProps({
    model: Object,
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }
    if (!store.state.appContext.showSideBar) {
        store.dispatch(APP_SHOW_SIDEBAR);
    }
});
</script>

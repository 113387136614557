<template>
  <SiteHeader />
  <div
    id="top-nav-app"
    class="container-fluid site-header position-relative pl-0"
  >
    <CascadeMenu />
  </div>
  <NotificationsBanner
    v-if="notificationMessage.showNotifications"
    :model="notificationMessage"
  />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import CascadeMenu from '../../components/CascadeMenu.vue';
import NotificationsBanner from '../../components/NotificationsBanner.vue';
import SiteHeader from '../../components/SiteHeader.vue';

const store = useStore();
const notificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.notificationMessage);

</script>
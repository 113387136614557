export const defaultGenericErrorModel = {
    errorTitle: 'Ett fel har inträffat!',
    errorText: '\u003cp\u003eEtt generellt fel har intr\u0026auml;ffat!\u003c/p\u003e\n\u003cp\u003e\u003ca title="Kontakta oss" href="/link/6836721dd0ac4899aa8d2db1852f8209.aspx"\u003eKontakta oss\u003c/a\u003e f\u0026ouml;r att f\u0026aring; hj\u0026auml;lp!\u003c/p\u003e',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const policeReportErrorModel = {
    errorTitle: 'Ett fel har inträffat!',
    errorText: "<p>Din polisrapport kunde tyvärr inte laddas upp, vänligen försök igen.</p> <p>Kontrollera att filstorleken inte överstiger 100 mb och att filen laddas upp i formatet: pdf, tiff, png, gif, jpg eller jpeg.</p><p>Om problemet kvarstår, vänligen kontakta <a href='mailto:utredningen@uc.se' target='_blank'>utredningen@uc.se</a></p>",
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const policeReportSuccessModel = {
    errorTitle: 'Polisanmälan uppladdad',
    errorText: "Vi kontrollerar din polisanmälan och meddelar dig via sms och e-post när den blivit godkänd eller om du behöver komplettera.",
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const changeCardModelLoading = {
    errorTitle: 'Byt kort',
    iconClass: 'far fa-credit-card',
    isLoading: true,
    errorText: '',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const changeCardModel = {
    errorTitle: 'Byt kort',
    iconClass: 'far fa-credit-card',
    isLoading: false,
    errorText: '',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

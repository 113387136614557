import { getCreditReport, getChallenges, getSavingGoals, } from '../../providers/creditWatchApiProviders';
import { getCreditChartData } from '../../../utils/creditUtils';

const CUSTOMER_CREDIT_INFO = 'CUSTOMER_CREDIT_INFO';
export const CUSTOMER_CREDIT_INFO_FETCH = `${CUSTOMER_CREDIT_INFO}/FETCH`;
const CUSTOMER_CREDIT_INFO_UPDATE = `${CUSTOMER_CREDIT_INFO}/UPDATE`;
const CUSTOMER_CREDIT_INFO_FAILED = `${CUSTOMER_CREDIT_INFO}/FAILED`;
const CUSTOMER_CREDIT_INFO_PENDING = `${CUSTOMER_CREDIT_INFO}/PENDING`;
export const CUSTOMER_CREDIT_INFO_FETCH_CHALLENGES = `${CUSTOMER_CREDIT_INFO}/FETCH_CHALLENGES`;
const CUSTOMER_CREDIT_INFO_UPDATE_CHALLENGES = `${CUSTOMER_CREDIT_INFO}/UPDATE_CHALLENGES`;
const CUSTOMER_CREDIT_INFO_PENDING_CHALLENGES = `${CUSTOMER_CREDIT_INFO}/PENDING_CHALLENGES`;
const CUSTOMER_CREDIT_INFO_FAILED_CHALLENGES = `${CUSTOMER_CREDIT_INFO}/FAILED_CHALLENGES`;
export const CUSTOMER_CREDIT_INFO_FETCH_SAVING_GOALS = `${CUSTOMER_CREDIT_INFO}/FETCH_SAVING_GOALS`;
const CUSTOMER_CREDIT_INFO_UPDATE_SAVING_GOALS = `${CUSTOMER_CREDIT_INFO}/UPDATE_SAVING_GOALS`;
const CUSTOMER_CREDIT_INFO_PENDING_SAVING_GOALS = `${CUSTOMER_CREDIT_INFO}/PENDING_SAVING_GOALS`;
const CUSTOMER_CREDIT_INFO_FAILED_SAVING_GOALS = `${CUSTOMER_CREDIT_INFO}/FAILED_SAVING_GOALS`;

const emptyCustomerCreditInfo = {
    creditReport: {},
    creditChartData: {},
    hirePurchaseData: null,
    mortgagesChartData: {},
    ucscoreChartData: {},
}

const state = {
    customerCreditInfo: emptyCustomerCreditInfo,
    loaded: false,
    failed: false,
    pending: false,
    creditChallenges: {},
    creditChallengesLoaded: false,
    creditChallengesFailed: false,
    creditChallengesPending: false,
    savingGoals: [],
    savingGoalsLoaded: false,
    savingGoalsFailed: false,
    savingGoalsPending: false,
};

const mutations = {
    [CUSTOMER_CREDIT_INFO_UPDATE](state, newModel) {
        state.customerCreditInfo = newModel;
        state.customerCreditInfo.creditChartData = getCreditChartData(newModel.creditReport);
        state.loaded = true;
        state.failed = false;
        state.pending = false;
    },
    [CUSTOMER_CREDIT_INFO_FAILED](state) {
        state.customerCreditInfo = emptyCustomerCreditInfo;
        state.loaded = true;
        state.failed = true;
        state.pending = false;
    },
    [CUSTOMER_CREDIT_INFO_PENDING](state) {
        state.customerCreditInfo = emptyCustomerCreditInfo;
        state.loaded = false;
        state.failed = false;
        state.pending = true;
    },
    [CUSTOMER_CREDIT_INFO_UPDATE_CHALLENGES](state, newModel) {
        state.creditChallenges = newModel;
        state.creditChallengesLoaded = true;
        state.creditChallengesPending = false;
        state.creditChallengesFailed = false;
    },
    [CUSTOMER_CREDIT_INFO_PENDING_CHALLENGES](state) {
        state.creditChallenges = {};
        state.creditChallengesLoaded = false;
        state.creditChallengesPending = true;
        state.creditChallengesFailed = false;
    },
    [CUSTOMER_CREDIT_INFO_FAILED_CHALLENGES](state) {
        state.creditChallenges = {};
        state.creditChallengesLoaded = true;
        state.creditChallengesPending = false;
        state.creditChallengesFailed = true;
    },
    [CUSTOMER_CREDIT_INFO_UPDATE_SAVING_GOALS](state, newModel) {
        state.savingGoals = newModel;
        state.savingGoalsLoaded = true;
        state.savingGoalsPending = false;
        state.savingGoalsFailed = false;
    },
    [CUSTOMER_CREDIT_INFO_PENDING_SAVING_GOALS](state) {
        state.savingGoals = [];
        state.savingGoalsLoaded = false;
        state.savingGoalsPending = true;
        state.savingGoalsFailed = false;
    },
    [CUSTOMER_CREDIT_INFO_FAILED_SAVING_GOALS](state) {
        state.savingGoals = [];
        state.savingGoalsLoaded = true;
        state.savingGoalsPending = false;
        state.savingGoalsFailed = true;
    },
};

const actions = {
    [CUSTOMER_CREDIT_INFO_FETCH]({ commit }) {
        commit(CUSTOMER_CREDIT_INFO_PENDING);
        return getCreditReport().then(response => {
            commit(CUSTOMER_CREDIT_INFO_UPDATE, response.data);
        }).catch(() => {
            commit(CUSTOMER_CREDIT_INFO_FAILED);
        });
    },
    [CUSTOMER_CREDIT_INFO_FETCH_CHALLENGES]({ commit }) {
        commit(CUSTOMER_CREDIT_INFO_PENDING_CHALLENGES);
        return getChallenges().then(response => {
            commit(CUSTOMER_CREDIT_INFO_UPDATE_CHALLENGES, response.data);
        }).catch(() => {
            commit(CUSTOMER_CREDIT_INFO_FAILED_CHALLENGES);
        });
    },
    [CUSTOMER_CREDIT_INFO_FETCH_SAVING_GOALS]({ commit }) {
        commit(CUSTOMER_CREDIT_INFO_PENDING_SAVING_GOALS);
        return getSavingGoals().then(response => {
            commit(CUSTOMER_CREDIT_INFO_UPDATE_SAVING_GOALS, response.data);
        }).catch(() => {
            commit(CUSTOMER_CREDIT_INFO_FAILED_SAVING_GOALS);
        });
    },
};

export const customerCreditInfoContex = {
    state,
    mutations,
    actions
};

<template>
  <div
    class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3"
    data-testid="dashboard-remarks-page"
  >
    <MobileMenu />
    <div class="dashboard__card">
      <div class="row">
        <div class="col-12 mt-2 d-md-none">
          <RouterLink
            class="return__overview-btn d-flex align-items-center mb-2"
            to="/mina-tjanster"
          >
            <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
            <span class="body-small font-weight-medium">Översikt</span>
          </RouterLink>
        </div>
        <div class="col-12 mt-2">
          <h2>{{ model.name }}</h2>
          <p v-html="model.description.value" />
          <h4>Dina anmärkningar</h4>
        </div>
        <div class="col-12 d-none d-md-block">
          <Remarks
            v-if="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation != null"
            :show-end-date="true"
            :complaints="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation.historicalComplaintsSpecified"
          />
          <RemarksKfm
            v-if="customerCreditInfo.creditReport.ctiConsumerPaymentInformation != null"
            :show-end-date="true"
            :complaints="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation"
          />
        </div>
        <div class="col-12 d-md-none ">
          <RemarksMobile
            v-if="customerCreditInfo.creditReport.ctiConsumerPaymentInformation != null"
            :show-end-date="true"
            :complaints="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation.historicalComplaintsSpecified"
          />
          <RemarksKfmMobile
            v-if="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation != null"
            :show-end-date="true"
            :complaints="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation"
          />
        </div>
        <div
          v-if="customerCreditInfo.creditReport?.ctiConsumerPaymentInformation == null"
          class="col-12 mt-5"
        >
          <p v-html="model.noRemarks.value" />
        </div>
      </div>
    </div>
    <RelatedArticles
      v-if="model.relatedArticles.length > 0"
      :articles="model.relatedArticles.expandedValue"
    />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import Remarks from '../components/Remarks.vue';
import RemarksMobile from '../components/RemarksMobile.vue';
import RemarksKfm from '../components/RemarksKfm.vue';
import RemarksKfmMobile from '../components/RemarksKfmMobile.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue'
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const props = defineProps({
    model: Object,
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
});
</script>


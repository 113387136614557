<template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between p-2">
        <h4>
          Kontokredit
        </h4>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
          @click="openCreditCardQuestion" aria-label="Förklaring Kontokredit" />
      </div>
      <div v-if="hasTerminatedCredits" class="d-flex justify-content-end my-3">
        <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
          variant="secondary" @click="showTerminated = !showTerminated">
          {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
        </NdsButton>
      </div>
      <div v-if="credits != null && credits.length > 0" class="block__enento__accordion__card-wrapper">
        <template v-for="(inquiry, index) in credits">
          <div v-if="inquiry.toDate == null || showTerminated" :key="index" class="block__enento__accordion__card">
            <div class="panel-heading">
              <a class="d-block collapsed p-3 pr-5" data-toggle="collapse" :href="`#credit-${index}`">
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>

            <div :id="`credit-${index}`" class="collapse p-3">
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 pl-0 text-right">
                  {{ NumberFormat(inquiry.grantedCredit) }} kr
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 pl-0 color-ocean text-right">
                  {{ NumberFormat(inquiry.usedCredit) }} kr
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.fromDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.updatedAtDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 pl-0 color-red text-right">
                    {{ FormatDate(inquiry.toDate.timeStamp.value) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <ZeroState v-else icon="far fa-credit-card"
      :text="model.descriptionNoCreditDevelopment.value || 'Ingen Kredit registrerad'" :isRichText="true" />
    </div>
    <div class="col-md-12 mt-3">
      <div class="d-flex justify-content-between p-2">
        <h4 class="mb-0">
          Kreditkortsutveckling
        </h4>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
          @click="openCreditDevelopmentQuestion" aria-label="Förklaring Kreditkortsutveckling" />
      </div>
      <div class="ndsCreditChartContainer">
        <NdsCreditBarChart title="" emptyTitle="Ingen kredit registrerad" :creditData="creditData" />
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { NumberFormat, FormatDate } from '../_helpers.js';
import { NdsCreditBarChart, NdsButton, NdsIconButton } from '@nds/vue';
import ConfirmModal from '../components/modals/ConfirmModal.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const credits = ref([]);
const showTerminated = ref(false);
const hasTerminatedCredits = ref(false);
const creditData = computed(() => ({
  grantedCreditsLabel: 'Beviljad Kredit',
  grantedCreditsLabelTooltip: 'Beviljad',
  usedCreditsLabel: 'Utnyttjad kredit',
  usedCreditsLabelTooltip: 'Utnyttjad',
  entries: props.report.creditChartData.entries,
}));

const { open: openCreditCardQuestion, close: closeCreditCardQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: props.model.questionCreditCardTitle.value || 'Kontokredit',
    icon: 'far fa-question-circle',
    body: props.model.questionCreditCard.value,
    onConfirm() {
      closeCreditCardQuestion();
    },
    onCancel() {
      closeCreditCardQuestion();
    },
  },
});

const { open: openCreditDevelopmentQuestion, close: closeCreditDevelopmentQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: props.model.questionCreditDevelopmentTitle.value || 'Kreditkortsutveckling',
    icon: 'far fa-question-circle',
    body: props.model.questionCreditDevelopment.value,
    onConfirm() {
      closeCreditDevelopmentQuestion();
    },
    onCancel() {
      closeCreditDevelopmentQuestion();
    },
  },
});

onMounted(() => {
  props.report.creditReport.ctiCreditsWithInquirer.creditInformations.forEach((item) => {
    if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 1) {
      credits.value.push(item);
      if (item.toDate != null) {
        hasTerminatedCredits.value = true;
      }
    }
  });
});
</script>
<style scoped>
.ndsCreditChartContainer {
  width: 100%;
  height: 320px;
}
</style>
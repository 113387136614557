<template>
  <div
    id="fraudbarrierpage"
    class="dashboard col-md-8 col-lg-9"
    data-testid="dashboard-fraud-barrier-page"
  >
    <div class="row">
      <div class="col-12 d-md-none">
        <RouterLink
          class="return__overview-btn d-flex align-items-center mb-2"
          to="/mina-tjanster"
        >
          <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
          <span class="body-small">Översikt</span>
        </RouterLink>
      </div>
      <div class="col-lg-5">
        <div
          v-if="fraudBarrierStatus === null"
          class="dashboard__card p-0 py-3"
        >
          <div class="px-3 mb-3">
            <h2 class="mb-0">
              Bedrägerispärr
            </h2>
            <div class="align-items-center justify-content-center d-flex">
              <NdsLoader />
            </div>
          </div>
        </div>
        <FraudBarrierToggle
          v-else
          :fraud-barrier-status="fraudBarrierStatus"
          :model="model"
        />
      </div>
      <div class="col-lg-7">
        <div
          v-if="fraudBarrierStatus === null"
          class="dashboard__card p-0 py-3"
        >
          <div class="px-3 mb-3">
            <h4 class="mb-0">
              Ladda upp polisrapport
            </h4>
            <div class="align-items-center justify-content-center d-flex">
              <NdsLoader />
            </div>
          </div>
        </div>
        <div
          v-else
          class="dashboard__card"
        >
          <div
            v-if="fraudBarrierStatus.FraudBarrierStatus === 'APPROVED'"
            class="fraudbarrier__wrapper"
          >
            <div class="fraudbarrier__wrapper-header fraudbarrier-approved mb-3">
              <p class="mb-0">
                Din polisrapport har blivit godkänd. Du har nu aktiv bedrägerispärr i 5 år
              </p>
            </div>
            <p>
              Kontakta <a
                href="mailto:utredningen@uc.se"
                target="_blank"
              >utredningen@uc.se</a> om du vill komplettera
              eller ta bort din polisrapport
            </p>
          </div>
          <div
            v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'RECEIVED_AWAITING_APPROVAL'"
            class="fraudbarrier__wrapper"
          >
            <div class="fraudbarrier__wrapper-header fraudbarrier-awaitingapproval mb-3">
              <p>Nedan kan du se din uppladdade polisrapport som just nu granskas.</p>
              <p class="mb-0">
                Kontakta <a
                  href="mailto:utredningen@uc.se"
                  target="_blank"
                >utredningen@uc.se</a> om du vill komplettera
                eller ta bort din polisrapport
              </p>
            </div>
            <h4>Din uppladdade polisrapport granskas</h4>
            <div class="d-flex align-items-center mt-3">
              <p class="body-small font-weight-bold mb-2">
                <i class="far fa-clock fa-fw mr-2" />Filer granskas
              </p>
            </div>
            <div
              v-for="(policeReportNames, index) in fraudBarrierStatus.PoliceReportNames"
              :key="index"
              class="fraudbarrier-review-item position-relative p-3 mb-2"
            >
              <span class="body-small d-block">{{ policeReportNames }}</span>
              <span class="body-xsmall d-block status-label">Granskas</span>
            </div>
          </div>
          <div
            v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'
            "
            class="fraudbarrier__wrapper"
          >
            <div
              v-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4"
            >
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då vi inte kunnat läsa in filen korrekt.
              </p>
            </div>
            <div
              v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4"
            >
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då din uppladdade fil inte är komplett.
              </p>
            </div>
            <div
              v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4"
            >
              <p class="mb-0">
                Din uppladdade polisrapport har blivit nekad.
              </p>
            </div>
            <div
              v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4"
            >
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då filen du laddat upp inte är en polisrapport.
              </p>
            </div>
            <div
              v-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR' ||
                fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE' ||
                fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER' ||
                fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'
              "
              class="mb-4"
            >
              <p>
                För mer info kontakta <a
                  href="mailto:utredningen@uc.se"
                  target="_blank"
                >utredningen@uc.se</a> eller
                ladda upp en ny rapport och försök igen.
              </p>
            </div>
            <h4 class="mb-4">
              Skicka polisrapport
            </h4>
            <DropZone
              v-if="fraudBarrierStatus.PoliceReportCanUpload"
              @failed="showErrorModal"
              @success="showSuccessModal"
            />
          </div>
          <div
            v-else
            class="information-container"
          >
            <h4 class="mb-4">
              Skicka polisrapport
            </h4>
            <DropZone
              v-if="fraudBarrierStatus.PoliceReportCanUpload"
              @failed="showErrorModal"
              @success="showSuccessModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ErrorModal from '../components/modals/ErrorModal.vue';
import DropZone from '../components/DropZone.vue';
import { NdsLoader } from '@nds/vue';
import FraudBarrierToggle from '../components/FraudBarrierToggle.vue';
import { FRAUD_BARRIER_FETCH } from '../Scripts/store/modules/fraudBarrierContex';
import { policeReportErrorModel, policeReportSuccessModel } from '../components/modals/modalsUtils';

defineProps({
  model: Object,
  smallTitle: Boolean
});
const store = useStore();
const fraudBarrierStatus = computed(() => store.state.fraudBarrierContex.fraudBarrier);

const modalModel = ref(policeReportErrorModel);

const { open, close } = useModal({
    component: ErrorModal,
    attrs: {
        model: modalModel,
        onConfirm() {
            close();
        },
    },
});

onMounted(() => {
  if (!store.state.fraudBarrierContex.loaded) {
    store.dispatch(FRAUD_BARRIER_FETCH);
  }
});

const showSuccessModal = () => {
    store.dispatch(FRAUD_BARRIER_FETCH);
    modalModel.value = policeReportSuccessModel;
    open();
}

const showErrorModal = () => {
    modalModel.value = policeReportErrorModel;
    open();
}
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_variables.scss";

.fraudbarrier__wrapper-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: $ice-blue;
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem 1rem 1rem 4rem;
}

.fraudbarrier-review-item {
  border: 1px solid $gallery;
  border-radius: 6px;

  &:before {
    font-family: "Font Awesome 5 Pro";
    content: '\f15b';
    font-size: 1.25em;
    font-weight: 400;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
    color: $deep-ocean;
  }

  span {
    padding-left: 2rem;
  }
}

.status-label {
  color: $gray;
}

.upload-area {
  background-color: #ECF7F9;
  border: 1px dashed #333333;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  min-height: 235px;
}

.police-report-drop-zone {
  margin: 30px 0;
  padding: 15px;
  border: 2px dashed #dedede;
  color: #999;
  text-align: center;

  .file-list,
  .uploaded-file-list {
    padding: 0;
    margin: 0 0 15px 0;
    text-align: left;
    color: #333;

    li {
      list-style: none;
      overflow: hidden;
      white-space: nowrap;

      a {
        padding-right: 5px;
      }

      .fa-check {
        /*color: $green-haze;*/
        padding-right: 5px;
      }
    }

    label {
      color: #f07d00;
      cursor: pointer;
      margin: 0;
    }
  }

  label {
    cursor: pointer;
  }
}

.fraudbarrier__wrapper-header {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-size: 2em;
    top: calc(50% - 24px);
    left: 15px;
    font-weight: 400;
    position: absolute;
    color: $night-rider;
  }

  &.fraudbarrier-approved:before {
    content: '\f058';
  }

  &.fraudbarrier-awaitingapproval:before {
    content: '\f06a';
  }

  &.fraudbarrier-rejected:before {
    content: '\f071';
  }
}
</style>

<template>
  <div class="row">
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Bolån
        </h4>
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#property-loan-question"
          aria-expanded="false"
          aria-controls="property-loan-question"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <div
        v-if="hasTerminatedMortgages"
        class="row"
      >
        <div class="col-12">
          <button
            v-if="showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="fas fa-eye-slash" /> Dölj avslutade
          </button>
          <button
            v-if="!showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="far fa-eye" /> Visa avslutade
          </button>
        </div>
      </div>
      <div
        v-if="propertyMortgage.length > 0"
        class="block__enento__accordion__card-wrapper"
      >
        <template v-for="(inquiry,$index) in propertyMortgage">
          <div
            v-if="inquiry.toDate == null || showTerminated"
            :key="$index"
            class="block__enento__accordion__card"
          >
            <div class="panel-heading">
              <a
                class="d-block collapsed p-3 pr-5"
                data-toggle="collapse"
                :href="'#mortgage-'+$index"
              >
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>

            <div
              :id="'mortgage-'+$index"
              class="collapse p-3"
            >
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 text-right">
                  {{ NumberFormat(inquiry.grantedCredit) }} kr
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 color-ocean text-right">
                  {{ NumberFormat(inquiry.usedCredit) }} kr
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.fromDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.updatedAtDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 color-red text-right">
                    {{
                      new Date(inquiry.toDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Bolåneutveckling
        </h4>
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#mortgage-development-question"
          aria-expanded="false"
          aria-controls="unsecured-loan-development-question"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <MortgagesChart
        v-if="report.mortgagesChartData != null"
        :chart-data="report.mortgagesChartData"
      />
      <div class="row">
        <div class="col-md-12">
          <template v-if="report.mortgagesChartData != null">
            <p v-html="model.descriptionPropertyLoanDevelopment.value" />
          </template>
          <template v-else>
            <p v-html="model.descriptionNoPropertyLoanDevelopment.value" />
          </template>
        </div>
        <InfoModal
          id="property-loan-question"
          :title="model.questionPropertyLoanTitle.value"
          :body="model.questionPropertyLoan.value"
        />
        <InfoModal
          id="mortgage-development-question"
          :title="model.questionPropertyLoanDevelopmentTitle.value"
          :body="model.questionPropertyLoanDevelopment.value"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {NumberFormat, FormatDate} from '../_helpers.js';
import MortgagesChart from '@/components/charts/mortgagesChart.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
export default {
  name: 'Mortgages',
  components: {
    MortgagesChart,
    InfoModal
  },
  props: {
    model: Object,
    report: Object
  },
  data: function () {
    return {
      showTerminated: true,
      hasTerminatedCredits: false,
      hasTerminatedMortgages: false,
      hasTerminatedHirePurchase: false,
      hasTerminatedUnsecuredLoans: false,
      propertyMortgage: []
    }
  },
  created: function () {
    var self = this;
    if (self.report.creditReport.ctiCreditsWithInquirer != null) {
      self.report.creditReport.ctiCreditsWithInquirer.creditInformations.forEach(function (item) {
        if (self.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] == 7) {
          self.propertyMortgage.push(item);
          if (item.toDate != null) self.hasTerminatedMortgages = true;
        } else if (self.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] == 9) {
          self.propertyMortgage.push(item);
          if (item.toDate != null) self.hasTerminatedMortgages = true;
        }
      });
    }

  }, methods: {
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    },
  }
}
</script>
import { getEssentialCustomerInfo } from '../../providers/frontendApiControllerProviders';
import {
    postUpdateCustomerSettings,
    postTerminateProductPackage
} from '../../providers/customerApiProvider';
import {
    postEnroll,
    getRedirectUrl,
} from '../../providers/onlineMonitoringApiProvider';
import { UMBRELLA_MEMBERS_SET_ID, UMBRELLA_MEMBERS_FETCH } from "./umbrellaMembersContext";

const CUSTOMER_INFO = 'CUSTOMER_INFO';
export const CUSTOMER_INFO_INITIATE_PREFETCHED = `${CUSTOMER_INFO}/INITIATE_PREFETCHED`;
export const CUSTOMER_INFO_FETCH = `${CUSTOMER_INFO}/FETCH`;
export const CUSTOMER_INFO_POST_SETTINGS = `${CUSTOMER_INFO}/POST_SETTINGS`;
export const CUSTOMER_INFO_POST_TERMINATE_PRODUCT = `${CUSTOMER_INFO}/POST_TERMINATE_PRODUCT`;
export const CUSTOMER_INFO_FETCH_PRODUCTS = `${CUSTOMER_INFO}/FETCH_PRODUCTS`;
export const CUSTOMER_INFO_PRODUCTS_RESET = `${CUSTOMER_INFO}/PRODUCTS_RESET`;
export const CUSTOMER_INFO_ENROLL_ONLINE_MONITORING = `${CUSTOMER_INFO}/ENROLL_ONLINE_MONITORING`;
export const CUSTOMER_INFO_OPEN_ONLINE_MONITORING = `${CUSTOMER_INFO}/OPEN_ONLINE_MONITORING`;
const CUSTOMER_INFO_UPDATE = `${CUSTOMER_INFO}/UPDATE`;
const CUSTOMER_INFO_UPDATE_PENDING = `${CUSTOMER_INFO}/UPDATE_PENDING`;
const CUSTOMER_INFO_UPDATE_FAILED = `${CUSTOMER_INFO}/UPDATE_FAILED`;
const CUSTOMER_INFO_HAS_RECENTLY_PAYED_INVOICE = `${CUSTOMER_INFO}/HAS_RECENTLY_PAYED_INVOICE`;
const CUSTOMER_INFO_UPDATE_PRODUCTS_RESET = `${CUSTOMER_INFO}/UPDATE_PRODUCTS_RESET`;
const CUSTOMER_INFO_EVENTS_UPDATE = `${CUSTOMER_INFO}/EVENTS_UPDATE`;
const CUSTOMER_INFO_REDIRECT_URL_UPDATE = `${CUSTOMER_INFO}/REDIRECT_URL_UPDATE`;
const CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED = `${CUSTOMER_INFO}/REDIRECT_URL_UPDATE_FAILED`;
const CUSTOMER_INFO_REDIRECT_URL_UPDATE_PENDING = `${CUSTOMER_INFO}/REDIRECT_URL_UPDATE_PENDING`;

const openUrlInAnotherTab = ({redirectUrl}) => {
    return window.open(redirectUrl, '_newtab' + Date.now());
}

const state = {
    customerEssentials: {
        displayCustomerReportProduct: false,
        displayCreditScoreProduct: false,
        displayCreditWatchProduct: false,
        displayIdProtectionProduct: false,
        displayDigitalMailbox: false,
        displayDigitalMailboxRegistered: false,
        customerId: 0,
        newEventCount: 0,
        numberOfNewItems: 0,
        lastLoginDate: 0,
        customerSocSecNo: 0,
        customerEmail: '',
        customerPhone: '',
        externalCustomerId: '',
        notificationByMail: true,
        notificationBySms: true,
        newsLetterSubscription: false,
        customerFullName: '',
        userHasAccessToDataMonitor: false,
        purchasedProductPackages: [],
        customerProducts: {},
    },
    customerEvents: [],
    hasRecentlyPayedInvoiceForTerminatedProduct: false,
    loaded: false,
    pending: false,
    failed: false,
    redirectUrl: null,
    redirectUrlFailed: false,
    redirectUrlPending: false,
};

const mutations = {
    [CUSTOMER_INFO_UPDATE](state, newModel) {
        state.customerEssentials = newModel;
        state.loaded = true;
        state.pending = false;
    },
    [CUSTOMER_INFO_UPDATE_PENDING](state) {
        state.hasRecentlyPayedInvoiceForTerminatedProduct = false;
        state.loaded = false;
        state.pending = true;
        state.failed = false;
    },
    [CUSTOMER_INFO_UPDATE_FAILED](state) {
        state.hasRecentlyPayedInvoiceForTerminatedProduct = false;
        state.loaded = true;
        state.pending = false;
        state.failed = true;
    },
    [CUSTOMER_INFO_HAS_RECENTLY_PAYED_INVOICE](state) {
        state.hasRecentlyPayedInvoiceForTerminatedProduct = true;
    },
    [CUSTOMER_INFO_REDIRECT_URL_UPDATE](state, newModel) {
        state.redirectUrlPending = false;
        state.redirectUrlFailed = false;
        state.redirectUrl = newModel.redirectUrl;
    },
    [CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED](state) {
        state.redirectUrlPending = false;
        state.redirectUrlFailed = true;
        state.redirectUrl = null;
    },
    [CUSTOMER_INFO_REDIRECT_URL_UPDATE_PENDING](state) {
        state.redirectUrlPending = true;
        state.redirectUrlFailed = false;
        state.redirectUrl = null;
    },
    [CUSTOMER_INFO_EVENTS_UPDATE](state, newModel) {
        const sortedCustomerEssentials = newModel?.customerEvents.sort((a, b) => {
            if (a.decisionDate < b.decisionDate) {
                return 1;
            } else if (a.decisionDate > b.decisionDate) {
                return -1;
            }
            return 0;
        }) || [];
        state.customerEvents = sortedCustomerEssentials;
    },
};

const actions = {
    [CUSTOMER_INFO_INITIATE_PREFETCHED]({ commit }, { customerEssentials }) {
        return commit(CUSTOMER_INFO_UPDATE, customerEssentials);
    },
    [CUSTOMER_INFO_FETCH]({ commit }, { reFetch, checkCancellableProducts }) {
        commit(CUSTOMER_INFO_UPDATE_PENDING);
        return getEssentialCustomerInfo({ reFetch, checkCancellableProducts }).then(response => {
            commit(CUSTOMER_INFO_UPDATE, response.data);
            if (response.data?.customerEvents?.CustomerEventList?.length) {
                commit(CUSTOMER_INFO_EVENTS_UPDATE, { customerEvents: response.data.customerEvents.CustomerEventList });
            }
        });
    },
    [CUSTOMER_INFO_POST_SETTINGS]({ commit, dispatch }, { csrfToken, email, phoneNumber, smsNotification, newsLetterSubscription, mailNotification }) {
        commit(CUSTOMER_INFO_UPDATE_PENDING);
        return postUpdateCustomerSettings({ csrfToken, email, phoneNumber, smsNotification, newsLetterSubscription, mailNotification }).then(() => {
            dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
        }).catch(() => {
            commit(CUSTOMER_INFO_UPDATE_FAILED);
        });
    },
    [CUSTOMER_INFO_POST_TERMINATE_PRODUCT]({ commit, dispatch }, { pppid, hasRecentlyPayedInvoice }) {
        return postTerminateProductPackage({ pppid }).then(() => {
            dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
            dispatch(CUSTOMER_INFO_FETCH_PRODUCTS, { refetchUmbrellaMembers: true });
            if (hasRecentlyPayedInvoice) {
                commit(CUSTOMER_INFO_HAS_RECENTLY_PAYED_INVOICE);
            }
        }).catch(() => {
            commit(CUSTOMER_INFO_UPDATE_FAILED);
        });
    },
    [CUSTOMER_INFO_FETCH_PRODUCTS]({ dispatch, state }, { refetchUmbrellaMembers }) {
        if (state.customerEssentials.customerProducts.umbrellaProductId) {
            dispatch(UMBRELLA_MEMBERS_SET_ID, { umbrellaProductId: state.customerEssentials.customerProducts.umbrellaProductId });
        }
        if (refetchUmbrellaMembers && state.customerEssentials.customerProducts.umbrellaProductId) {
            // TODO handle scenario when this action is called when umbrellaProductId is 0 in other words not availible
            dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: state.customerEssentials.customerProducts.umbrellaProductId });
        }
    },
    [CUSTOMER_INFO_PRODUCTS_RESET]({ commit }) {
        commit(CUSTOMER_INFO_UPDATE_PRODUCTS_RESET);
    },
    [CUSTOMER_INFO_ENROLL_ONLINE_MONITORING]({ commit, dispatch }, { monitoringBlockId, email }) {
        commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_PENDING);
        return postEnroll({ monitoringBlockId, email }).then(({ data: { redirectUrl }}) => {
            if(redirectUrl) {
                openUrlInAnotherTab({redirectUrl})
                commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE, { redirectUrl });
                return dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
            }
            return commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED);
        }).catch(() => {
            return commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED);
        });
    },
    [CUSTOMER_INFO_OPEN_ONLINE_MONITORING]({ commit, state }, { monitoringBlockId }) {
        if(state.redirectUrl) {
            return openUrlInAnotherTab({redirectUrl: state.redirectUrl})
        }
        commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_PENDING);
        return getRedirectUrl({ monitoringBlockId }).then(({ data: { redirectUrl }}) => {
            if(redirectUrl) {
                window.open(redirectUrl, '_newtab' + Date.now());
                return commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE, { redirectUrl });
            }
            return commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED);
        }).catch(() => {
            return commit(CUSTOMER_INFO_REDIRECT_URL_UPDATE_FAILED);
        });
    },
};

export const customerInfoContex = {
    state,
    mutations,
    actions
};

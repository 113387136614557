<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="info-modal-label"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header d-flex align-items-baseline">
          <h3
            id="info-modal-label"
            class="modal-title"
          >
            <i
              v-if="icon"
              :class="icon"
            />{{ title }}<span
              v-if="loading"
            >...</span>
          </h3>
          <i
            class="far fa-times hover-pointer"
            data-dismiss="modal"
            aria-label="Close"
            @click="error = false"
          />
        </div>
        <div
          v-if="!loading && !error"
          class="modal-body dashboard px-0"
        >
          <p
            v-if="reportContent == ''"
            class="px-4 d-block"
            v-html="body"
          />
          <p
            class="px-4 d-block"
            v-html="reportContent"
          />
          <div class="dashboard__table-column align-items-center px-4">
            <div class="form-check mb-3 py-1">
              <input
                id="generalInfoCheck"
                v-model="filter.generalInfo"
                type="checkbox"
                disabled
                class="form-check-input disabled"
              >
              <label
                class="form-check-label"
                for="generalInfoCheck"
              >Allmänna uppgifter
                (obligatoriskt)</label>
            </div>
            <div class="form-check mb-3 py-1">
              <input
                id="incomeCheck"
                v-model="filter.incomeStatement"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="incomeCheck"
              >Inkomstuppgifter</label>
            </div>
            <div class="form-check mb-3 py-1">
              <input
                id="previousPropertiesCheck"
                v-model="filter.consumerProperties"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="previousPropertiesCheck"
              >Fastigheter</label>
            </div>
            <div class="form-check mb-3 py-1">
              <input
                id="creditsCheck"
                v-model="filter.credits"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="creditsCheck"
              >Kreditengagemang</label>
            </div>
            <div class="form-check mb-3 py-1">
              <input
                id="remarksCheck"
                v-model="filter.remarks"
                type="checkbox"
                disabled
                class="form-check-input disabled"
              >
              <label
                class="form-check-label"
                for="remarksCheck"
              >Anmärkningar (obligatoriskt)</label>
            </div>
            <div class="form-check mb-3 py-1">
              <input
                id="inquiriesCheck"
                v-model="filter.inquiries"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="inquiriesCheck"
              >Senaste frågor hos UC</label>
            </div>
          </div>
        </div>
        <div v-if="loading">
          <CircleLoader />
          <p class="text-center">
            Vi skapar din upplysning<br>
            Vänligen vänta i några sekunder...
          </p>
        </div>
        <div
          v-if="error"
          class="modal-body dashboard px-0"
        >
          <div class="row justify-content-center mb-3">
            <div class="d-block w-100 text-center">
              <div class="d-flex justify-content-center">
                <span class="icon-circle input-group-text justify-content-center">
                  <i class="far fa-2x fa-exclamation-triangle" />
                </span>
              </div>
              <p class="color-nightrider font-weight-bold mt-3 px-3">
                Något gick fel
              </p>
              <p class="color-nightrider mt-3 px-3">
                PDF:en kunde inte skapas, vänligen försök igen
              </p>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-enento-secondary"
            data-dismiss="modal"
            @click="error = false"
          >
            Avbryt
          </button>
          <button
            type="button"
            class="btn btn-enento-blue"
            :disabled="loading"
            @click="CreatePDF"
          >
            Skapa
            PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircleLoader from '@/components/CircleLoader.vue';
import $ from 'jquery';
export default {
    name: 'CreatePdfModal',
    components: {
        CircleLoader
    },
    props: {
        title: String,
        body: String,
        id: String,
        icon: String,
        report: Object
    },
    data: function () {
        return {
            loading: false,
            filter: {
                generalInfo: true,
                remarks: true,
                inquiries: true,
                credits: true,
                incomeStatement: true,
                consumerProperties: true
            },
            engagementCheck: false,
            reportContent: "",
            error: false
        }

    },
    methods: {
        CreatePDF: function () {
            var self = this;
            this.loading = !this.loading;
            this.error = false;
            $.ajax({
                url: "/mina-tjanster/print-pdf",
                type: "POST",
                data: this.filter,
                xhrFields: {
                    responseType: 'blob'
                },
                success: function (response) {
                    // Create a blob URL for the PDF response
                    var blobUrl = URL.createObjectURL(response);

                    // Create a link element and simulate a click to trigger the download
                    var link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = "min_upplysning.pdf";
                    link.click();

                    // Open the downloaded PDF in a new tab or window
                    window.open(blobUrl, "_blank");

                    // Release the blob URL after the download is initiated
                    URL.revokeObjectURL(blobUrl);



                    self.loading = !self.loading;
                    $("#" + self.id).modal("hide");
                },
                error: function (xhr, status, error) {
                    console.log("Error in the API request:", error);
                    self.loading = !self.loading;
                    self.error = true;
                }
            });
        }
    }
}
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_variables.scss";

.form-group label,
.modal-title {
    color: #000;
}

.form-check-input {
    height: 24px;
    width: 24px;
    margin-top: 0;
    accent-color: $dark-ocean;

    &.disabled {
        opacity: 1 !important;
    }
}

</style>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";

.icon-circle {
    background-color: $beach;
    width: 70px;
    height: 70px;
    border-radius: 50%;

    i {
        color: $apricot;
    }
}
</style>
<template>
  <div class="row">
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Kontokredit
        </h4>
        <!--<i v-if="model.questionCreditCard != null && model.questionCreditCard != ''" class="fal fa-lg fa-question-square" v-on:click="questionCreditCard=!questionCreditCard"></i>-->
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#credit-card-question"
          aria-expanded="false"
          aria-controls="credit-card-question"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <div
        v-if="hasTerminatedCredits"
        class="row"
      >
        <div class="col-12">
          <button
            v-if="showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="fas fa-eye-slash" /> Dölj avslutade
          </button>
          <button
            v-if="!showTerminated"
            class="toggleButton"
            @click="showTerminated = !showTerminated"
          >
            <i class="far fa-eye" /> Visa avslutade
          </button>
        </div>
      </div>
      <div
        v-if="credits != null && credits.length > 0"
        class="block__enento__accordion__card-wrapper"
      >
        <template v-for="(inquiry,index) in credits">
          <div
            v-if="inquiry.toDate == null || showTerminated"
            :key="index"
            class="block__enento__accordion__card"
          >
            <div class="panel-heading">
              <a
                class="d-block collapsed p-3 pr-5"
                data-toggle="collapse"
                :href="'#credit-'+index"
              >
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>

            <div
              :id="'credit-'+index"
              class="collapse p-3"
            >
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 text-right">
                  {{ NumberFormat(inquiry.grantedCredit) }} kr
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 color-ocean text-right">
                  {{ NumberFormat(inquiry.usedCredit) }} kr
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.fromDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 text-right">
                    {{
                      new Date(inquiry.updatedAtDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 color-red text-right">
                    {{
                      new Date(inquiry.toDate.timeStamp.value).toLocaleDateString('sv-SE')
                    }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div class="new-creditwatch-icon-wrapper">
        <h4 class="mb-0">
          Kreditkortsutveckling
        </h4>
        <a
          class="ml-auto"
          data-toggle="modal"
          data-target="#credit-development-question"
          aria-expanded="false"
          aria-controls="credit-development-question"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <CreditChart
        v-if="report.creditChartData != null"
        :chart-data="report.creditChartData"
      />
      <div class="row">
        <div class="col-md-12">
          <template v-if="report.creditChartData != null">
            <p v-html="model.descriptionCreditDevelopment.value" />
          </template>
          <template v-else>
            <p v-html="model.descriptionNoCreditDevelopment.value" />
          </template>
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    id="credit-card-question"
    :title="model.questionCreditCardTitle.value"
    :body="model.questionCreditCard.value"
  />
  <InfoModal
    id="credit-development-question"
    :title="model.questionCreditDevelopmentTitle.value"
    :body="model.questionCreditDevelopment.value"
  />
</template>
<script>
import { FormatDate,NumberFormat } from '../_helpers.js';
import CreditChart from '@/components/charts/CreditChart.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
export default {
  name: 'CreditCard',
  components: {
    CreditChart,
    InfoModal
  },
  props: {
    model: {},
    report: {
      creditChartData: null,
      creditReport: {}
    }
  },
  data(){
    return{
      credits: [],
      showTerminated: true,
      hasTerminatedCredits: false,
      hasTerminatedMortgages: false,
      hasTerminatedHirePurchase: false,
      hasTerminatedUnsecuredLoans: false
    }
  },
  mounted: function () {
    var self = this;
    self.report.creditReport.ctiCreditsWithInquirer.creditInformations.forEach(function (item) {
      if (self.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] == 1) {
        self.credits.push(item);
        if (item.toDate != null) self.hasTerminatedCredits = true;
      }
    });
  },methods: {
    FormatDate: function(timestamp){
      return FormatDate(timestamp);
    },
    NumberFormat(num) {
      return NumberFormat(num);
    },
  }
}
</script>
<template>
  <div class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3" data-testid="dashboard-credits-page">
    <MobileMenu />
    <div class="dashboard__card">
      <div class="row">
        <div class="col-12 mt-2 d-md-none">
          <RouterLink class="return__overview-btn d-flex align-items-center mb-2" to="/mina-tjanster">
            <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
            <span class="body-small font-weight-medium">Översikt</span>
          </RouterLink>
        </div>
        <div class="col-12 mt-2">
          <h2>{{ model.name }}</h2>
          <p v-html="model.description.value" />
        </div>
        <div v-if="pending" class="col-12 mt-2 row">
          <NdsLoader />
        </div>
        <div v-else class="col-md-12">
          <div class="row">
            <div class="col-md-5">
              <div class="new-creditwatch-icon-wrapper">
                <h4 class="mb-0">
                  UC Riskprognos
                </h4>
                <a class="ml-auto" data-toggle="modal" data-target="#risk-forecast-question" aria-expanded="false"
                  aria-controls="risk-forecast-question">
                  <i class="far fa-question-circle color-darkocean hover-pointer" />
                </a>
              </div>

              <NdsRiskDoughnutChart title="" subTitle="Ditt betyg är" :value="riskGrade" />
              <div v-if="riskGrade === 0" class="col-12">
                <p v-html="model.descriptionNoScoreGrade.value" />
              </div>
            </div>

            <div class="col-md-7">
              <div class="new-creditwatch-icon-wrapper">
                <h4 class="mb-0">
                  Senaste frågor hos UC
                </h4>
                <a class="ml-auto" data-toggle="modal" data-target="#latest-inquiries-question" aria-expanded="false"
                  aria-controls="latest-inquiries-question">
                  <i class="far fa-question-circle color-darkocean hover-pointer" />
                </a>
              </div>
              <ConsumerInquiries v-if="ctiConsumerInquires" :consumer-inquiries="ctiConsumerInquires" />
              <ZeroState
                v-else
                icon="far fa-check"
                text="Ingen förfrågan registrerad hos UC"
                ata-cy="min-upplysning-general-zerostate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showInqueriesQuestions" class="dashboard__card pt-0">
      <div class="row">
        <ul class="nav nav-tabs d-flex flex-nowrap w-100 border-0" role="tablist">
          <li class="d-flex nav-item text-nowrap" role="presentation">
            <a href="#profile" class="active" aria-controls="profile" role="tab" data-toggle="tab">Kontokredit</a>
          </li>
          <li class="d-flex nav-item text-nowrap" role="presentation">
            <a href="#messages" aria-controls="messages" role="tab" data-toggle="tab">Avbetalningskredit</a>
          </li>
          <li class="d-flex flex-fill nav-item">
            <a href="#" class="pe-none flex-fill" />
          </li>
        </ul>
        <div v-if="pending" class="col-md-12 row">
          <NdsLoader />
        </div>
        <div v-else class="col-md-12">
          <div class="tab-content py-4">
            <div id="profile" role="tabpanel" class="tab-pane active">
              <CreditCard v-if="customerCreditInfo?.creditReport?.ctiCreditsWithInquirer" :report="customerCreditInfo"
                :model="model" />
            </div>
            <div id="messages" role="tabpanel" class="tab-pane">
              <DownPaymentCredit v-if="customerCreditInfo?.creditReport?.ctiCreditsWithInquirer"
                :report="customerCreditInfo" :model="model" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasLoans" class="dashboard__card">
      <p v-html="model.descriptionNoLoans.value" />
    </div>
    <InfoModal id="risk-forecast-question" title="Här ser du din UC Riskprognos"
      :body="model.questionRiskForecast.value" />
    <InfoModal id="latest-inquiries-question" title="" :body="model.questionLatestCreditInquiries.value" />
    <RelatedArticles :articles="model.relatedArticles.expandedValue" />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import ConsumerInquiries from '../components/ConsumerInquiries.vue';
import CreditCard from '../components/CreditCard.vue';
import DownPaymentCredit from '../components/DownPaymentCredit.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import InfoModal from '../components/modals/InfoModal.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import MobileMenu from '../components/MobileMenu.vue';
import { NdsRiskDoughnutChart, NdsLoader } from '@nds/vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const props = defineProps({
  model: Object
});
const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const riskGrade = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.creditScore?.riskGrade ? store.state.customerCreditInfoContex.customerCreditInfo.creditReport.creditScore.riskGrade * 10 : 0);
const ctiConsumerInquires = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiConsumerInquires?.inquiries?.length > 0 ? store.state.customerCreditInfoContex.customerCreditInfo?.creditReport.ctiConsumerInquires : null);
const showInqueriesQuestions = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.ctiUnsecuredCreditDetail?.numberOfHirePurchaseCredits > 0 || store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiUnsecuredCreditDetail?.numberOfActiveAccountCredits > 0);
const hasLoans = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiUnsecuredCreditDetail?.numberOfHirePurchaseCredits === 0 && store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiUnsecuredCreditDetail?.numberOfActiveAccountCredits === 0);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});
</script>
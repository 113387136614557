import { getMenu, getBottomMenu, getSideBarMenu } from '../../providers/frontendApiControllerProviders';
import { clearPersistedStorage } from '../storeUtils';

const APP = 'APP';
export const APP_HIDE_MOD = `${APP}/HIDE_MODAL`;
export const APP_SHOW_MODAL = `${APP}/SHOW_MODAL`;
export const APP_HIDE_MODAL = `${APP}/HIDE_MODAL`;
export const APP_SHOW_SIDEBAR = `${APP}/SHOW_SIDEBAR`;
export const APP_HIDE_SIDEBAR = `${APP}/HIDE_SIDEBAR`;
export const APP_TOGGLE_SIDEBAR = `${APP}/TOGGLE_SIDEBAR`;
export const APP_SHOW_REPORT = `${APP}/SHOW_REPORT`;
export const APP_HIDE_REPORT = `${APP}/HIDE_REPORT`;
export const APP_SHOW_SIDE_NAVIGATION = `${APP}/SHOW_SIDE_NAVIGATION`;
export const APP_HIDE_SIDE_NAVIGATION = `${APP}/HIDE_SIDE_NAVIGATION`;
export const APP_FETCH_MENU = `${APP}/FETCH_MENU`;
export const APP_FETCH_SIDEBAR_MENU = `${APP}/FETCH_SIDEBAR_MENU`;
export const APP_FETCH_BOTTOM_MENU = `${APP}/FETCH_BOTTOM_MENU`;
export const APP_IS_LOGGED_IN = `${APP}/IS_LOGGED_IN`;
export const APP_IS_LOGGED_OUT = `${APP}/IS_LOGGED_OUT`;
const APP_UPDATE = `${APP}/UPDATE`;

const APP_UPDATE_MENU = `${APP}/UPDATE_MENU`;
const APP_UPDATE_MENU_FAILED = `${APP}/UPDATE_MENU_FAILED`;
const APP_UPDATE_MENU_PENDING = `${APP}/UPDATE_MENU_PENDING`;

const APP_UPDATE_SIDEBAR_MENU = `${APP}/UPDATE_SIDEBAR_MENU`;
const APP_UPDATE_SIDEBAR_MENU_FAILED = `${APP}/UPDATE_SIDEBAR_MENU_FAILED`;
const APP_UPDATE_SIDEBAR_MENU_PENDING = `${APP}/UPDATE_SIDEBAR_MENU_PENDING`;

const APP_UPDATE_BOTTOM_MENU = `${APP}/UPDATE_BOTTOM_MENU`;
const APP_UPDATE_BOTTOM_MENU_FAILED = `${APP}/UPDATE_BOTTOM_MENU_FAILED`;
const APP_UPDATE_BOTTOM_MENU_PENDING = `${APP}/UPDATE_BOTTOM_MENU_FAILED`;

const defaultMenu = {
    mobileMenu: [],
    desktopMenu: {},
    notificationsMessages: {},
}

const hasExpired = (iat) => {
    const date = new Date(iat);
    const halfHour = 1000 * 60 * 30;
    const halfHourAgo = Date.now() - halfHour;

    return date.valueOf() < halfHourAgo;
};

const state = {
    modalShowing: false,
    menu: defaultMenu,
    menuLoaded: false,
    menuFailed: false,
    menuPending: false,
    sideBarMenu: [],
    sideBarMenuLoaded: false,
    sideBarMenuFailed: false,
    sideBarMenuPending: false,
    bottomMenu: [],
    bottomMenuLoaded: false,
    bottomMenuFailed: false,
    bottomMenuPending: false,
    showSideBar: false,
    showReportOverlay: false,
    isLoggedin: false,
    iat: new Date().toJSON(),
    showLoggedinNavigation: false,

};

const mutations = {
    [APP_UPDATE](state, newModel) {
        if (newModel.modalShowing) {
            state.modalShowing = newModel.modalShowing.newValue;
        }
        if (newModel.showSideBar) {
            if (newModel.showSideBar.newValue === 'toggle') {
                state.showSideBar = !state.showSideBar;
            } else {
                state.showSideBar = newModel.showSideBar.newValue;
            }
        }
        if (newModel.showReportOverlay) {
            state.modalShowing = newModel.showReportOverlay.newValue;
        }
        if (newModel.isLoggedIn) {
            state.isLoggedin = newModel.isLoggedIn.newValue;
        }
        if (newModel.showLoggedinNavigation) {
            state.showLoggedinNavigation = newModel.showLoggedinNavigation.newValue;
        }
        if (newModel.menu) {
            state.menu = newModel.menu.newValue;
            state.menuLoaded = true;
            state.menuFailed = false;
            state.menuPending = false;
        }
    },
    [APP_UPDATE_MENU](state, newModel) {
        state.menu = newModel;
        state.menuLoaded = true;
        state.menuFailed = false;
        state.menuPending = false;
    },
    [APP_UPDATE_MENU_FAILED](state) {
        state.menu = defaultMenu;
        state.menuLoaded = true;
        state.menuFailed = true;
        state.menuPending = false;
    },
    [APP_UPDATE_MENU_PENDING](state) {
        state.menu = defaultMenu;
        state.menuLoaded = false;
        state.menuFailed = false;
        state.menuPending = true;
    },

    [APP_UPDATE_SIDEBAR_MENU](state, newModel) {
        state.sideBarMenu = newModel;
        state.sideBarMenuLoaded = true;
        state.sideBarMenuFailed = false;
        state.sideBarMenuPending = false;
    },
    [APP_UPDATE_SIDEBAR_MENU_FAILED](state) {
        state.sideBarMenu = [];
        state.sideBarMenuLoaded = true;
        state.sideBarMenuFailed = true;
        state.sideBarMenuPending = false;
    },
    [APP_UPDATE_SIDEBAR_MENU_PENDING](state) {
        state.sideBarMenu = [];
        state.sideBarMenuLoaded = false;
        state.sideBarMenuFailed = false;
        state.sideBarMenuPending = true;
    },

    [APP_UPDATE_BOTTOM_MENU](state, newModel) {
        state.bottomMenu = newModel;
        state.bottomMenuLoaded = true;
        state.bottomMenuFailed = false;
        state.bottomMenuPending = false;
    },
    [APP_UPDATE_BOTTOM_MENU_FAILED](state) {
        state.bottomMenu = [];
        state.bottomMenuLoaded = true;
        state.bottomMenuFailed = true;
        state.bottomMenuPending = false;
    },
    [APP_UPDATE_BOTTOM_MENU_PENDING](state) {
        state.bottomMenu = [];
        state.bottomMenuLoaded = false;
        state.bottomMenuFailed = false;
        state.bottomMenuPending = true;
    },
};

const actions = {
    [APP_FETCH_MENU]({ commit }) {
        commit(APP_UPDATE_MENU_PENDING);
        return getMenu().then(response => {
            commit(APP_UPDATE_MENU, response.data);
        }).catch(() => {
            commit(APP_UPDATE_MENU_FAILED);
        });
    },
    [APP_FETCH_SIDEBAR_MENU]({ commit }) {
        commit(APP_UPDATE_SIDEBAR_MENU_PENDING);
        return getSideBarMenu().then(response => {
            commit(APP_UPDATE_SIDEBAR_MENU, response.data);
        }).catch(() => {
            commit(APP_UPDATE_SIDEBAR_MENU_FAILED);
        });
    },
    [APP_FETCH_BOTTOM_MENU]({ commit }) {
        commit(APP_UPDATE_BOTTOM_MENU_PENDING);
        return getBottomMenu().then(response => {
            commit(APP_UPDATE_BOTTOM_MENU, response.data);
        }).catch(() => {
            commit(APP_UPDATE_BOTTOM_MENU_FAILED);
        });
    },
    [APP_SHOW_MODAL]({ commit }) {
        return commit(APP_UPDATE, { modalShowing: { newValue: true } });
    },
    [APP_HIDE_MODAL]({ commit }) {
        return commit(APP_UPDATE, { modalShowing: { newValue: false } });
    },
    [APP_SHOW_SIDEBAR]({ commit }) {
        return commit(APP_UPDATE, { showSideBar: { newValue: true } });
    },
    [APP_HIDE_SIDEBAR]({ commit }) {
        return commit(APP_UPDATE, { showSideBar: { newValue: false } });
    },
    [APP_TOGGLE_SIDEBAR]({ commit }) {
        return commit(APP_UPDATE, { showSideBar: { newValue: 'toggle' } });
    },
    [APP_SHOW_REPORT]({ commit }) {
        return commit(APP_UPDATE, { showReportOverlay: { newValue: true } });
    },
    [APP_HIDE_REPORT]({ commit }) {
        return commit(APP_UPDATE, { showReportOverlay: { newValue: false } });
    },
    [APP_SHOW_SIDE_NAVIGATION]({ commit }) {
        return commit(APP_UPDATE, { showLoggedinNavigation: { newValue: true } });
    },
    [APP_HIDE_SIDE_NAVIGATION]({ commit }) {
        return commit(APP_UPDATE, { showLoggedinNavigation: { newValue: false } });
    },
    [APP_IS_LOGGED_IN]({ commit, state, dispatch }, { serversideRenderedMenues }) {
        if (hasExpired(state.iat) || state.isLoggedin === false) {
            clearPersistedStorage();
        }
        if(!serversideRenderedMenues) {
            dispatch(APP_FETCH_MENU);
            return commit(APP_UPDATE, { isLoggedIn: { newValue: true } });
        }
        return commit(APP_UPDATE, { isLoggedIn: { newValue: true }, menu: { newValue: serversideRenderedMenues } });
    },
    [APP_IS_LOGGED_OUT]({ commit, state, dispatch }, { serversideRenderedMenues }) {
        if (hasExpired(state.iat) || state.isLoggedin === true) {
            clearPersistedStorage();
        }
        if(!serversideRenderedMenues) {
           dispatch(APP_FETCH_MENU);
           return commit(APP_UPDATE, { isLoggedIn: { newValue: false } });
        }
        return commit(APP_UPDATE, { isLoggedIn: { newValue: false }, menu: { newValue: serversideRenderedMenues } });
    },
};

export const appContext = {
    state,
    mutations,
    actions
};

<template>
  <div
    class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3"
    data-testid="dashboard-my-saving-goals-page"
  >
    <div id="savinggoals-page">
      <MobileMenu />
      <div class="dashboard__card">
        <div class="row">
          <div class="col-12 mt-2 d-md-none">
            <RouterLink
              class="return__overview-btn d-flex align-items-center mb-2"
              to="/mina-tjanster"
            >
              <i class="far fa-chevron-left fa-xs fa-fw mr-1" />
              <span class="body-small font-weight-medium">Översikt</span>
            </RouterLink>
          </div>
          <div class="col-12 mt-2">
            <h2>{{ model.headline.value }}</h2>
            <p v-html="model.description.value" />
          </div>

          <div
            v-if="savingGoalsFailed"
            class="col-12 mt-2"
          >
            <Reloader @reload="reFetchSavingGoals" />
          </div>
          <div
            v-else-if="savingGoalsPending"
            class="col-12 mt-2"
          >
            <CircleLoader />
          </div>
          <SavingGoals
            v-else-if="savingGoalsLoaded"
            :model="model"
            :saving-goals="savingGoals"
          />

          <div
            v-if="creditChallengesFailed"
            class="col-12 mt-2"
          >
            <Reloader @reload="reFetchChallanges" />
          </div>
          <div
            v-else-if="creditChallengesPending"
            class="col-12 mt-2"
          >
            <CircleLoader />
          </div>
          <Challenges
            v-else-if="creditChallengesLoaded"
            :model="model"
            :challenges-data="creditChallenges"
          />
        </div>
      </div>
      <RelatedArticles
        v-if="model.relatedArticles.length > 0"
        :articles="model.relatedArticles.expandedValue"
      />
      <MobileBottomMenu />
    </div>
  </div>
</template>
<script setup lang="js">
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import Challenges from '../components/Challenges.vue';
import SavingGoals from '../components/SavingGoals.vue';
import CircleLoader from '../components/CircleLoader.vue';
import Reloader from '../components/Reloader.vue';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import {
  CUSTOMER_CREDIT_INFO_FETCH_CHALLENGES,
  CUSTOMER_CREDIT_INFO_FETCH_SAVING_GOALS
} from '../Scripts/store/modules/customerCreditInfoContex';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex';

const props = defineProps({ model: Object });
const store = useStore();
const savingGoals = computed(() => store.state.customerCreditInfoContex.savingGoals);
const savingGoalsLoaded = computed(() => store.state.customerCreditInfoContex.savingGoalsLoaded);
const savingGoalsPending = computed(() => store.state.customerCreditInfoContex.savingGoalsPending);
const savingGoalsFailed = computed(() => store.state.customerCreditInfoContex.savingGoalsFailed);
const creditChallenges = computed(() => store.state.customerCreditInfoContex.creditChallenges);
const creditChallengesLoaded = computed(() => store.state.customerCreditInfoContex.creditChallengesLoaded);
const creditChallengesPending = computed(() => store.state.customerCreditInfoContex.creditChallengesPending);
const creditChallengesFailed = computed(() => store.state.customerCreditInfoContex.creditChallengesFailed);


onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.savingGoalsLoaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH_CHALLENGES);
  }
  if (!store.state.customerCreditInfoContex.creditChallengesLoaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH_SAVING_GOALS);
  }
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "SAVING_GOAL" });
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});

const reFetchChallanges = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH_CHALLENGES);
};
const reFetchSavingGoals = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH_SAVING_GOALS);
};
</script>

<template>
  <div class="unsecuredloans chart-border">
    <div class="row legends px-3">
      <div class="col-6">
        <div class="legend-square" />
        <span class="legend-label">Totalt beviljat</span>
      </div>
      <div class="col-6">
        <div class="legend-square sand" />
        <span class="legend-label">Totalt utnyttjat</span>
      </div>
    </div>
    <div id="unsecured-loans-development" />
  </div>
</template>
<script>
import Highcharts from 'highcharts';
import { NumberFormat } from '../../_helpers.js'
export default {
    name: 'UnsecuredLoansChart',
    props: {
        chartData: {}
    },
    mounted: function () {
        this.RenderChart(this.chartData.ctiUnsecuredCreditSummary_usedCredits, this.chartData.ctiUnsecuredCreditSummary_grantedCredits, "unsecured-loans-development", "Utnyttjat");
    },
    methods: {
        RenderChart: function (chartData, chartData2, chartId) {
            var data = [];
            var data2 = [];
            if (typeof chartData !== 'undefined') {
                chartData.SeriesLine.forEach(function (item) {
                    data.push(item.y);
                });
            }
            if (typeof chartData !== 'undefined') data.push(chartData.CurrentPoint.y);

            if (typeof chartData2 !== 'undefined' && chartData2 != null) {
                var i = 0;
                chartData2.SeriesLine.forEach(function (item) {
                    data2.push(item.y - chartData.SeriesLine[i].y);
                    i++;
                });
            }
            if (typeof chartData2 !== 'undefined' && chartData2 != null) data2.push(chartData2.CurrentPoint.y - chartData.CurrentPoint.y);

            Highcharts.chart(chartId, {

                chart: {
                    type: 'column',
                    styledMode: true,
                    width: null,
                    backgroundColor: "#fff"
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        rotation: 0,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Gilroy',
                            color: "#848484",
                            fontWeight: 600
                        },
                        formatter: function () {
                            return '<span class="font-weight-bold">' + this.value.substring(2, 6) + '</span><br><span>' + this.value.substring(7, 11) + '</span>';
                        }
                    },
                    categories: chartData.Categories
                    //tickPositions: positions
                },
                yAxis: {
                    visible: true,
                    //minRange: 100,
                    //labels: {
                    //    x: -15
                    //}
                    //width:"1%"
                },
                legend: {
                    enabled: true,
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    x: 0,
                    y: 100
                },
                plotOptions: {
                    column: {
                        groupPadding: 0.1,
                        pointPadding: 0,
                        stacking: 'normal',
                        //pointWidth:52,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false,
                            crop: false,
                            overflow: "allow",
                            formatter: function () {
                                return this.y > 999999 ? (this.y / 1000000).toFixed(2) + "M" : this.y;
                            }
                        },
                        color: "#848484",
                        label: {
                            maxFontSize: "14px"
                        },
                        style: {
                            fontWeight: 400
                        }
                    }

                },
                tooltip: {
                    className: "chart-tooltip",
                    borderWidth: 0,
                    shadow: true,
                    style: {
                        padding: 0
                    },
                    shared: false,
                    useHTML: true,
                    //headerFormat: '<div class="chart-tooltip"><span class="tooltip-header">{point.key}</span><br/><ul>',
                    //footerFormat: '</ul></div>',
                    formatter: function () {
                        this.data = data;
                        this.data2 = data2;
                        return '<div class="chart-tooltip" style="height:65px;"><span class="tooltip-header">' + this.key + '</span><br/><ul>' +
                            //'<li class="tooltip-label">Beviljat: ' + NumberFormat(this.data2[this.point.index]) + " kr</li>" +
                            '<li class="tooltip-label">Utnyttjat: ' + NumberFormat(this.data[this.point.index]) + ' kr</li>';
                    },
                    valueDecimals: 2
                },
                series: [{
                    name: 'Totalt beviljat',
                    column: {
                        color: "#F5EAE3"
                    },
                    //colorByPoint: true,
                    //color: '#F09678',
                    data: data2,
                    tooltip: {
                        pointFormatter: function () {
                            return '<li class="tooltip-label">Beviljat: ' + NumberFormat(this.total) + " kr</li>";
                        }
                    }
                },
                {
                    name: 'Totalt utnyttjat',
                    column: {
                        color: "#036D82"
                    },
                    //colorByPoint: true,
                    marker: {
                        symbol: "circle",
                        fillColor: '#004755'
                    },
                    color: '#036D82',
                    data: data,
                    tooltip: {
                        pointFormatter: function () {
                            return '<li class="tooltip-label">Utnyttjat: ' + NumberFormat(this.y) + " kr</li>";
                        }
                    }
                }],
            });
        }
    }
}
</script>
<template>
  <!--<div class="dashboard__card">-->
  <div class="group-invite-container d-flex flex-column">
    <p v-if="umbrella.size != null">
      Här kan du se vilka som är medlemmar i ditt ID-Skydd, bjuda in nya och säga upp befintliga medlemmar. Du kan bjuda
      in {{ NumberOfInvitationsLeft() }} personer till.
    </p>
    <h4 v-if="invitations.length > 0 || members.length > 0">
      Medlemmar
    </h4>
    <table
      v-if="invitations.length > 0 || members.length > 0"
      class="table d-none d-lg-table"
    >
      <thead>
        <tr>
          <td style="width: 25%">
            Namn
          </td>
          <td style="width: 35%">
            Mail
          </td>
          <td>Status</td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(invite, index) in invitations"
          :key="`group_invite_invetation_${index}`"
          :class="OddOrEven(index + 1)"
        >
          <td>{{ invite.name }}</td>
          <td>{{ invite.email }}</td>
          <td><span class="py-1 px-3 status-label invited">Inbjuden</span></td>
          <td class="text-right">
            <a
              href="#"
              @click="DeleteGroupInvite(invite.name)"
            >Säg upp medlem</a>
          </td>
        </tr>
        <tr
          v-for="(member, index) in members"
          :key="`group_invite_memeber_${index}`"
          :class="OddOrEven(index + invitations.length + 1)"
        >
          <td>{{ member.name }}</td>
          <td>{{ member.email }}</td>
          <td
            v-if="!member.zombie"
            class="align-middle body-xsmall"
          >
            <span
              class="py-1 px-3 status-label active"
            >Aktiv</span>
          </td>
          <td v-else>
            <span class="py-1 px-3 status-label cancelled">Uppsagd</span>
          </td>
          <td
            v-if="!member.zombie"
            class="text-right"
          >
            <a
              href="#"
              @click="DeleteGroupMembership(member.purchasedProductPackageId, umbrella.purchasedProductPackageId)"
            >Säg
              upp medlem</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="invitations.length > 0 || members.length > 0"
      class="mobile__group__invite-wrapper mt-2 d-lg-none"
    >
      <div
        v-for="(invite, index) in invitations"
        :key="`group_invite_list_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke"
        :class="OddOrEven(index + 1)"
      >
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ invite.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Mejladress</span>
          <span class="color-gray">{{ invite.email }}</span>
        </div>
        <div class="position-relative py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <span class="status-label invited">Inbjuden</span>
          <a
            href="#"
            @click="DeleteGroupInvite(invite.name)"
          >Säg upp medlem</a>
        </div>
      </div>
      <div
        v-for="(member, index) in members"
        :key="`group_invite_memeber_info_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke"
        :class="OddOrEven(index + invitations.length + 1)"
      >
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ member.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Mejladress</span>
          <span class="color-gray">{{ member.email }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <span
            v-if="!member.zombie"
            class="status-label active"
          >Aktiv</span>
          <span
            v-else
            class="status-label cancelled"
          >Uppsagd</span>
          <a
            v-if="!member.zombie"
            href="#"
            @click="DeleteGroupMembership(member.purchasedProductPackageId, umbrella.purchasedProductPackageId)"
          >Säg
            upp medlem</a>
        </div>
      </div>
    </div>
    <InviteMember
      :group-id="groupId"
      :members="members"
      :invitations="invitations"
      :umbrella="umbrella"
    />
    <DeleteInvite
      :group-id="groupId"
      :name="selectedInvite"
    />
    <DeleteMember
      :group-id="groupId"
      :member="selectedMember"
    />
  </div>
  <!--</div>-->
</template>
<script>
import $ from 'jquery';
import InviteMember from './modals/InviteMember.vue';
import DeleteInvite from './modals/DeleteInvite.vue';
import DeleteMember from './modals/DeleteMember.vue';

export default {
  name: 'Groupinvite',
  components: {
    InviteMember,
    DeleteMember,
    DeleteInvite,
  },
  props: {
    invitations: Array,
    members: Array,
    groupId: Number,
    umbrella: Object,
  },
  data: function () {
    return {
      selectedInvite: "",
      selectedMember: {
        memberId: {},
        ownerId: {}
      },
      umbrellaData: {
        size: 0
      }
    }
  },
  created: function () {
    this.umbrellaData = this.umbrella;
  },
  methods: {
    NumberOfInvitationsLeft: function () {
      return this.umbrella.size - (this.members.length + this.invitations.length);
    },
    OddOrEven: function (index) {
      return (index % 2) > 0 ? "odd" : "even";
    },
    DeleteGroupInvite(name) {
      this.selectedInvite = name;
      $('#deleteInviteModal').modal('show');
    },
    DeleteGroupMembership(memberId, ownerId) {
      this.selectedMember.memberId = memberId;
      this.selectedMember.ownerId = ownerId;
      $('#deleteMemberModal').modal('show');
    },
    GetOwnedUmbrellas() {
      this.$parent.GetOwnedUmbrellas();
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.group-invite-container {
  .mobile__group__invite__member {
    a {
      position: absolute;
      right: 16px;
      top: calc(50% - 15px);
    }
  }

  .status-label {
    background-color: $light-smoke;
    border-radius: 6px;
    padding: 2px 4px;
    text-align: center;

    &.invited {
      color: $orangegold;
      background-color: rgba(242, 201, 76, 0.1);
    }

    &.active {
      color: $green-haze;
      background-color: rgba(38, 153, 82, 0.1);
    }

    &.cancelled {
      color: $monza;
      background-color: $feedback-error-300;
    }
  }

  table {
    .even {
      background-color: $white;
    }

    tr:hover td {
      background: inherit;
      color: inherit;
    }

    td {
      border-top: 0;
      padding: 11px 16px;
      font-size: 14px;
      vertical-align: middle;
    }

    tbody tr {
      background-color: $light-smoke;

      td:first-child {
        width: 18%;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }

      td:nth-child(2) {
        width: 20%;
        word-break: break-all;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }
    }
  }
}
</style>
import axios from 'axios';

const post = ({ url, data, headers }) => {
    return axios({
        method: 'post',
        url,
        data,
        headers
    });
};

const basePath = '/CustomerApi';
const changeProtectionStatusPath = `${basePath}/ChangeProtectionStatus`;
const updateCustomerSettingsPath = `${basePath}/UpdateCustomerSettings`;
const getNeteasyChangeCardPaymentIdPath = `${basePath}/GetNeteasyChangeCardPaymentId`;
const terminateProductPackagedPath = `${basePath}/TerminateProductPackage`;
const createCustomerPath = `${basePath}/CreateCustomer`;
const freePurchasePath = `${basePath}/FreePurchase`;
const createPaymentPath = `${basePath}/CreatePayment`;
const uploadProtectionReportPath = `${basePath}/UploadProtectionReport`;

export const postChangeProtectionStatus = ({ newStatus, csrfToken }) => {
    const formData = new FormData();
    formData.append('__RequestVerificationToken', csrfToken);
    formData.append('newStatus', newStatus);
    return post({
        url: changeProtectionStatusPath,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export const postUpdateCustomerSettings = ({
    csrfToken,
    email,
    phoneNumber,
    smsNotification,
    newsLetterSubscription,
    mailNotification
}) => {
    const formData = new FormData();
    formData.append('__RequestVerificationToken', csrfToken);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('smsNotification', smsNotification);
    formData.append('newsLetterSubscription', newsLetterSubscription);
    formData.append('mailNotification', mailNotification);
    return post({
        url: updateCustomerSettingsPath,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export const postNeteasyChangeCardPaymentId = ({ pppid }) => post(
    {
        url: getNeteasyChangeCardPaymentIdPath,
        data: { pppid },
        headers: { Accept: 'application/json' }
    });
export const postTerminateProductPackage = ({ pppid }) => post(
    {
        url: terminateProductPackagedPath,
        data: { pppid },
        headers: { Accept: 'application/json' }
    });
export const postCreateCustomer = ({ personNumber, email, phoneNumber }) => post(
    {
        url: createCustomerPath,
        data: {
            personNumber,
            email,
            phoneNumber,
        },
        headers: { Accept: 'application/json' }
    });
export const postFreePurchase = ({ customerId, productPackageIds }) => post(
    {
        url: freePurchasePath,
        data: {
            customerId,
            productPackageIds,
        },
        headers: { Accept: 'application/json' }
    });
export const postCreatePayment = ({ externalCustomerId, productPackageIds }) => post(
    {
        url: createPaymentPath,
        data: {
            externalCustomerId,
            productPackageIds,
        },
        headers: { Accept: 'application/json' }
    });

export const postUploadProtectionReport = ({ csrfToken, formData }) => {
    formData.append("__RequestVerificationToken", csrfToken);
    return post(
        {
            url: uploadProtectionReportPath,
            data: formData,
            headers: { Accept: 'application/json' }
        })
};

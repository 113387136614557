<template>
  <div
    v-if="propertyMortgage.length > 0"
    class="d-xl-none"
  >
    <div
      v-for="(inquiry, index) in propertyMortgage"
      :key="`property_mortage_inquiry_${index}`"
    >
      <div class="dashboard__table-column">
        <div class="d-flex align-items-center">
          <h5>{{ inquiry.creditor }}</h5>
        </div>
        <div class="d-flex align-items-center">
          <p class="mb-0">
            Kreditlimit:
          </p>
          <p class="mb-0 ml-auto">
            {{ formatToSek(inquiry.grantedCredit) }}
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="color-ocean">
            Utnyttjad kredit:
          </p>
          <p class="color-ocean ml-auto">
            {{ formatToSek(inquiry.usedCredit) }}
          </p>
        </div>
        <div
          v-if="inquiry.fromDate != null"
          class="d-flex align-items-center"
        >
          <p class="mb-0">
            Startdatum:
          </p>
          <p
            v-if="inquiry.fromDate.timeStamp.value === 946684800000"
            class="mb-0 ml-auto"
          >
            -
          </p>
          <p
            v-else
            class="mb-0 ml-auto"
          >
            {{ FormatDate(inquiry.fromDate.timeStamp.value) }}
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p>Senast uppdaterad:</p>
          <p class="ml-auto">
            {{ FormatDate(inquiry.updatedAtDate.timeStamp.value) }}
          </p>
        </div>
        <div
          v-if="inquiry.creditNumber != null"
          class="d-flex align-items-center"
        >
          <p class="body-xsmall mb-2 color-gray">
            Kreditnummer
          </p>
          <p class="body-xsmall mb-2 color-gray ml-auto">
            {{ inquiry.creditNumber }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="propertyMortgage.length > 0"
    class="d-none d-xl-block"
  >
    <h4 class="mb-2">
      {{ title }}
    </h4>
    <p>Aktuell skuld: <span class="color-ocean">{{ formatToSek(totalDebt) }}</span></p>
    <div class="row">
      <div
        v-for="(inquiry, index) in propertyMortgage"
        :key="`property_mortage_inquiry_depth_${index}`"
        class="col-md-6 mb-2"
      >
        <div class="block__enento__accordion__card-wrapper">
          <div class="block__enento__accordion__card">
            <div class="dashboard__table border-0 mb-0">
              <div class="dashboard__table-column">
                <div class="d-flex align-items-center">
                  <h5>{{ inquiry.creditor }}</h5>
                </div>
                <div class="d-flex align-items-center">
                  <p class="mb-0">
                    Kreditlimit:
                  </p>
                  <p class="mb-0 ml-auto">
                    {{ formatToSek(inquiry.grantedCredit) }}
                  </p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="color-ocean">
                    Utnyttjad kredit:
                  </p>
                  <p class="color-ocean ml-auto">
                    {{ formatToSek(inquiry.usedCredit) }}
                  </p>
                </div>
                <div
                  v-if="inquiry.fromDate != null"
                  class="d-flex align-items-center"
                >
                  <p class="mb-0">
                    Startdatum:
                  </p>
                  <p
                    v-if="inquiry.fromDate.timeStamp.value === 946684800000"
                    class="mb-0 ml-auto"
                  >
                    -
                  </p>
                  <p
                    v-else
                    class="mb-0 ml-auto"
                  >
                    {{ FormatDate(inquiry.fromDate.timeStamp.value) }}
                  </p>
                </div>
                <div class="d-flex align-items-center">
                  <p>Senast uppdaterad:</p>
                  <p class="ml-auto">
                    {{ FormatDate(inquiry.updatedAtDate.timeStamp.value) }}
                  </p>
                </div>
                <div
                  v-if="inquiry.creditNumber != null"
                  class="d-flex align-items-center"
                >
                  <p class="body-xsmall mb-2 color-gray">
                    Kreditnummer
                  </p>
                  <p class="body-xsmall mb-2 color-gray ml-auto">
                    {{ inquiry.creditNumber }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ZeroState
    v-else
    icon="far fa-sack-dollar"
    text="Ingen kredit registrerad"
  />
  <span class="d-none d-xl-block body-xsmall mt-2 mb-0"><i class="far fa-book mr-1" />Källa: Kreditgivare i Sverige
  </span>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { FormatDate, formatToSek, } from '../../_helpers.js';
import ZeroState from '../../components/myinformation/ZeroState.vue';

const props = defineProps({
  report: Object,
  creditType: Number,
  title: String
});

const totalDebt = ref(0);
const propertyMortgage = ref([]);

onMounted(() => {
  if (props.report.creditReport.ctiCreditsWithInquirer) {
    props.report.creditReport.ctiCreditsWithInquirer.creditInformations.forEach((item) => {
        if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === props.creditType) {
          if (!item.toDate) {
            propertyMortgage.value.push(item);
          }
          totalDebt.value += item.usedCredit;
        }
      });
    }
});

</script>
<template>
  <div
    class="row"
    data-testid="checkout-confirmation"
  >
    <template v-if="pending">
      <CircleLoader />
    </template>
    <template v-else-if="selectedProduct">
      <div class="col-12 d-md-none">
        <h2 class="d-md-none text-center mb-4">
          {{ model.checkoutConfirmationTitle }}
        </h2>
      </div>
      <div
        class="col-md-6 checkout__checkoutconfirmation-contentwrapper rounded-border text-center text-md-left py-4 py-md-0 pl-md-0 mx-2 mx-md-0"
      >
        <h2 class="d-none d-md-block">
          {{ model.checkoutConfirmationTitle }}
        </h2>
        <h3 class="gilroy-bold color-ocean">
          {{ selectedProduct.productPackage.name }}
        </h3>
        <h4 class="color-emperor">
          Totalt: {{ selectedProduct.productPackage.price }} kr
          {{ selectedProduct.productPackagePriceDescription }}
        </h4>
        <div
          class="mb-3 mb-md-5"
          v-html="model.checkoutConfirmationDescription"
        />
        <a
          v-if="isLoggedin"
          href="/mina-tjanster"
          class="btn btn-default btn-lg btn-wide w-100-md mb-4"
        >Gå till mina
          tjänster</a>
        <a
          v-else
          href="/logga-in"
          class="btn btn-default btn-lg btn-wide w-100-md mb-4"
        >Logga in</a>
        <div v-html="model.checkoutConfirmationHelpText" />
      </div>
      <div class="col-md-6 text-center text-md-left py-4 py-md-0 px-2 px-md-3">
        <h3 class="gilroy-bold mb-3">
          {{ model.checkoutConfirmationAlsoInterestedInTitle }}
        </h3>
        <FullImageCardBlock
          v-if="model.checkoutConfirmationAlsoInterestedIn"
          :model="model.checkoutConfirmationAlsoInterestedIn"
        />
      </div>
    </template>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FullImageCardBlock from '../blocks/FullImageCardBlock.vue';
import CircleLoader from '../../components/CircleLoader.vue';

defineProps({
  model: Object,
});

const store = useStore();
const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const pending = computed(() => store.state.customerInfoContex.pending);
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.checkout__checkoutconfirmation-contentwrapper {
  @include breakpoint(max-tablet-portrait) {
    background: $beach-secondary;
  }
}
</style>